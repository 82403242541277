import { AlertDescription } from './AlertDescription'
import { AlertIcon } from './AlertIcon'
import { AlertRoot } from './AlertRoot'
import { AlertTitle } from './AlertTitle'

export const Alert = {
  Description: AlertDescription,
  Icon: AlertIcon,
  Root: AlertRoot,
  Title: AlertTitle,
}
