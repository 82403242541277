import { Box, BoxProps } from '@chakra-ui/react'

type TemplateContentProps = BoxProps

export function TemplateContent({ children, ...props }: TemplateContentProps) {
  return (
    <Box
      as="main"
      flex="1"
      borderRadius={{ base: '0', md: '8px' }}
      background="platinum.100"
      padding={{ base: '1.5rem', lg: '2rem' }}
      {...props}
    >
      {children}
    </Box>
  )
}
