import {
  AlertTitle as ChakraAlertTitle,
  AlertTitleProps,
} from '@chakra-ui/react'

type IAlertTitleProps = AlertTitleProps

export function AlertTitle({ children, ...props }: IAlertTitleProps) {
  return (
    <ChakraAlertTitle fontSize="1.125rem" lineHeight="normal" {...props}>
      {children}
    </ChakraAlertTitle>
  )
}
