import { FlexProps } from '@chakra-ui/react'
import { Button } from '../Button'
import { Form } from '../Form'

interface TemplateFooterProps extends FlexProps {
  isLoading: boolean
  loadingText?: string
  openCancelModal: () => void
}

export function TemplateFooter({
  isLoading,
  loadingText,
  openCancelModal,
  ...props
}: TemplateFooterProps) {
  return (
    <Form.Footer {...props}>
      <Button.Tertiary onClick={openCancelModal}>Cancelar</Button.Tertiary>
      <Button.Primary
        type="submit"
        isLoading={isLoading}
        loadingText={loadingText}
      >
        Salvar
      </Button.Primary>
    </Form.Footer>
  )
}
