import { Button, ButtonProps, Icon } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { RiCheckboxCircleLine } from 'react-icons/ri'

interface ButtonSecondaryProps extends ButtonProps {
  icon?: IconType
}

export function ButtonSecondary({
  children,
  icon = RiCheckboxCircleLine,
  ...rest
}: ButtonSecondaryProps) {
  return (
    <Button
      _hover={{ color: 'white', bgColor: 'green.100' }}
      bgColor="green.50"
      color="white"
      leftIcon={<Icon as={icon} />}
      rounded={'0.25rem'}
      size="md"
      {...rest}
    >
      {children}
    </Button>
  )
}
