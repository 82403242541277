import { FormDivider } from './FormDivider'
import { FormFieldset } from './FormFieldset'
import { FormFooter } from './FormFooter'
import { FormLegend } from './FormLegend'
import { FormRoot } from './FormRoot'
import { FormSpan } from './FormSpan'
import { FormWrap } from './FormWrap'
import { Input } from './Input'
import { Password } from './Password'
import { Select } from './Select'

export * from './Input'
export * from './InputFile'
export * from './InputBase'
export * from './Password'
export * from './Select'

export const Form = {
  Divider: FormDivider,
  Fieldset: FormFieldset,
  Footer: FormFooter,
  Legend: FormLegend,
  Root: FormRoot,
  Span: FormSpan,
  Wrap: FormWrap,
  Input: Input,
  Password: Password,
  Select: Select,
}