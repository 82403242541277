import { Flex, FlexProps } from '@chakra-ui/react'

type HeadingRootProps = FlexProps

export function HeadingRoot({ children, ...props }: HeadingRootProps) {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      gap="1rem"
      marginBottom="3rem"
      wrap="wrap"
      {...props}
    >
      {children}
    </Flex>
  )
}
