import { AxiosResponse } from 'axios'
import { api } from '../../../services/axios'
import { Params, Response } from '../share'
import { OrphanData, TypeOrphan } from './'

export class ServicesOrphan {
  async get(params: Params): Promise<Response<TypeOrphan>> {
    const { registerPerPage, currentPage, profissionalId, institutionId } = params
    const url = `profissionais/${profissionalId}/instituicoes/${institutionId}/orfaos/?limit=${registerPerPage}&offset=${
      (currentPage - 1) * registerPerPage
    }`
    const {
      data: { count, next, previous, results },
    } = await api.get<Response<TypeOrphan>>(url)
    return { count, next, previous, results }
  }

  async create(orphanData: OrphanData): Promise<AxiosResponse<any, any>> {
    const url = 'orfaos/'
    const response = await api.post(url, orphanData)
    return response
  }
}
