import { ServicesAttachment } from '../attachment/ServicesAttachment'
import { ServicesInstitution } from '../institution/ServicesInstitution'
import { ServicesLogin } from '../login/ServicesLogin'
import { ServicesOrphan } from '../orphan'
import { ServicesRequest } from '../request'
import { ServicesPassword } from '../password'

export const services = {
  login: ServicesLogin,
  requests: new ServicesRequest(),
  institution: new ServicesInstitution(),
  orphan: new ServicesOrphan(),
  passwords: new ServicesPassword(),
  documents: new ServicesAttachment(),
}
