import { Spinner, SpinnerProps, useBreakpointValue } from '@chakra-ui/react'

type HeadingSpinnerProps = SpinnerProps

export function HeadingSpinner(props: HeadingSpinnerProps) {
  const isMobileVersion = useBreakpointValue({
    base: true,
    md: false,
  })
  return (
    <Spinner
      size={isMobileVersion ? 'sm' : 'md'}
      color="blue.300"
      ml="4"
      {...props}
    />
  )
}
