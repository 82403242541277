import {
  Flex,
  Icon,
  IconButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  useBreakpointValue,
} from '@chakra-ui/react'
import { RiCloseLine } from 'react-icons/ri'
import { Button } from '../Button'
import { Modal } from '../Modal'

interface TemplateCancelModalProps extends ModalProps {
  onConfirm: () => void
  title: string
  isLoading?: boolean
}

export function TemplateModal({
  children,
  title,
  isLoading,
  onConfirm,
  ...props
}: TemplateCancelModalProps) {
  const modalSize = useBreakpointValue({ base: 'xs', md: 'sm', '3xl': 'lg' })
  return (
    <Modal.Root size={modalSize} {...props}>
      <ModalOverlay />
      <ModalContent>
        <Modal.Header>
          <Flex justify="center" align="flex-start" width="100%" gap="10px">
            <Flex justify="center" align="flex-start" width="100%">
              {title}
            </Flex>
            <IconButton
              aria-label="Fechar modal"
              color="blackAlpha.900"
              icon={<Icon as={RiCloseLine} fontSize="1.5rem" />}
              mt="0.20rem"
              size="xs"
              variant="unstated"
              onClick={props.onClose}
            />
          </Flex>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button.Tertiary onClick={props.onClose}>Fechar</Button.Tertiary>
          <Button.Secondary
            onClick={onConfirm}
            isLoading={isLoading}
            loadingText="Confirmando"
          >
            Confirmar
          </Button.Secondary>
        </Modal.Footer>
      </ModalContent>
    </Modal.Root>
  )
}
