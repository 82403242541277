import {
  Flex,
  Icon,
  IconButton,
  useBreakpointValue,
  Button,
  Text,
} from '@chakra-ui/react'
import { RiMenuLine, RiLogoutBoxLine } from 'react-icons/ri'
import { useSidebarDrawer } from '../../data/contexts/SidebarDrawerContext'
import { Logo } from './Logo'
import { Profile } from './Profile'
import { useAuth } from '../../data/contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

export function Header() {
  const { onOpen } = useSidebarDrawer()
  const { signOut } = useAuth()
  const navigateTo = useNavigate()

  const handleLogout = () => {
    signOut()
    navigateTo('/')
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  const buttonSize = useBreakpointValue({
    base: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
  })

  return (
    <Flex
      as={'header'}
      w={'100%'}
      maxWidth={1480}
      h={'20'}
      mx={'auto'}
      px={'6'}
      align={'center'}
      justify={'space-between'}
      mb={{ base: '1rem', md: '2rem' }}
    >
      {!isWideVersion && (
        <IconButton
          aria-label={'Abrir menu'}
          color={'platinum.50'}
          icon={<Icon as={RiMenuLine} />}
          fontSize={'24'}
          variant={'unstated'}
          onClick={onOpen}
          mr={'2'}
        ></IconButton>
      )}

      <Logo />

      <Flex align={'center'} ml={'auto'}>
        <Profile showProfileData={isWideVersion} />
        <Button
          variant={'outline'}
          color={'white'}
          borderColor={'white'}
          size={buttonSize}
          _hover={{ bgColor: 'white', color: 'blue.300' }}
          leftIcon={
            <Icon
              as={RiLogoutBoxLine}
              fontSize={{ base: '0.75rem', sm: '0.875rem', md: '1rem' }}
            />
          }
          onClick={handleLogout}
          title="Sair"
          ml={2}
          borderRadius={'0.25rem'}
        >
          <Text
            fontWeight={'semibold'}
            lineHeight={'1.75rem'}
            fontSize={{ base: 'xs', sm: 'sm', md: 'md', lg: 'lg' }}
          >
            Sair
          </Text>
        </Button>
      </Flex>
    </Flex>
  )
}
