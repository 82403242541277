import {
  Flex,
  Heading,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Button } from '../../../components'
import { RequestFormData } from '../../../logic/core'
import { Data } from '../../../logic/utils'
import { api } from '../../../services/axios'
import { Card } from './Card'
import { Modal } from './Modal'

interface ModalJustificationProps {
  isOpen: boolean
  onClose: () => void
  requestData: RequestFormData
}

export function ModalJustification({
  isOpen,
  onClose,
  requestData,
}: ModalJustificationProps) {
  const pathUrl = api.defaults.baseURL?.replace('/api/', '')

  return (
    <>
      <ChakraModal
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="none"
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="1.5rem">Parecer da Avaliação</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Card.Root>
              <Card.Title>{requestData.status}</Card.Title>
              <Card.Content>
                <Card.Label>
                  Nome completo:
                  <Card.Info>{requestData.nome}</Card.Info>
                </Card.Label>
                <Card.Label>
                  Município:
                  <Card.Info>{requestData.municipio}</Card.Info>
                </Card.Label>
                <Card.Label>
                  Solicitado em:
                  <Card.Info>{Data.format(requestData.created_at)}</Card.Info>
                </Card.Label>
              </Card.Content>
            </Card.Root>

            <Flex
              flexDirection="column"
              alignItems="flex-start"
              gap="0.75rem"
              mt="1.5rem"
            >
              <Heading fontSize="1.25rem">Parecer geral da solicitação</Heading>
              <Modal.Opinion text={requestData.parecer}/>
            </Flex>

            <Heading fontSize="1.25rem" mt="1.5rem">
              Documentos
            </Heading>

            <Flex
              flexDirection="column"
              alignItems="flex-start"
              gap="1.5rem"
              my="1.5rem"
            >
              {requestData.anexos?.map(
                ({ id, tipo, justificativa, arquivo, valido }) => (
                  <Card.Wrap key={id}>
                    <Flex
                      minW="1rem"
                      borderRadius="8px 0 0 8px"
                      bgColor={valido ? 'green.50' : 'red.500'}
                    />
                    <Card.Content width="100%" m="1rem">
                      <Card.Label>
                        Status:
                        <Card.Info>{valido ? 'Válido' : 'Inválido'}</Card.Info>
                      </Card.Label>
                      <Card.Label>{tipo}</Card.Label>
                      {justificativa && (
                        <Card.Justification text={justificativa} />
                      )}
                      <Card.Link title={tipo} href={`${pathUrl}${arquivo}`}>
                        {`${tipo}.pdf`}
                      </Card.Link>
                    </Card.Content>
                  </Card.Wrap>
                ),
              )}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button.Tertiary type="button" size="md" onClick={onClose}>
              Fechar
            </Button.Tertiary>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  )
}
