import { Stack } from '@chakra-ui/react'
import { RiHomeHeartFill } from 'react-icons/ri'
import { NavLink } from './NavLink'
import { NavSection } from './NavSection'

export function SidebarNav() {
  return (
    <Stack spacing={'12'} align={'flex-start'}>
      <NavSection title={'GERAL'}>
        <NavLink href="/institutions" icon={RiHomeHeartFill}>
          Instituições
        </NavLink>
      </NavSection>
    </Stack>
  )
}
