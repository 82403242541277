export class Coin {
  static format(value: any): string {
    if (!Number(value)) return ''

    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100)
  }

  static unFormat(value: any) {
    return(
      value
        ?.toString()
        .replace(/[R$ ]/g, '')
        .replace('.', '')
        .replace(',', '.')
    )
  }

  static validate(value: any): boolean {
    if (value === '') {
      return true
    }

    const MAX_VALUE = 9999.99
    const { floatValue } = value
    return floatValue / 10000 <= MAX_VALUE
  }

  

}
