import { AxiosResponse } from 'axios'
import { api } from '../../../services/axios'
import { Params, Response } from '../share'
import { InstitutionData, TypeInstitution } from './'

export class ServicesInstitution {
  async get(params: Params): Promise<Response<TypeInstitution>> {
    const { registerPerPage, currentPage, profissionalId } = params

    const url = `profissionais/${profissionalId}/instituicoes/?limit=${registerPerPage}&offset=${
      (currentPage - 1) * registerPerPage
    }`
    const {
      data: { count, next, previous, results },
    } = await api.get<Response<TypeInstitution>>(url)

    return { count, next, previous, results }
  }

  async create(
    institutionData: InstitutionData,
  ): Promise<AxiosResponse<any, any>> {
    const url = `instituicoes/`
    const response = await api.post(url, institutionData)
    return response
  }

  async findById<T>(
    institutionId?: number,
    options?: any,
  ): Promise<AxiosResponse<any, any>> {
    const url = `instituicoes/${institutionId}/`
    const response = await api.get<T>(url, options)
    return response
  }
}
