import { AxiosRequestConfig } from 'axios'
import { api } from '../../../services/axios'

interface GetAttachments<C> {
  profissionalId: number | undefined
  orphanId: string | undefined
  institutionId: string | undefined
  requestId: string | undefined
  config?: AxiosRequestConfig<C>
}

interface AttachmentParams<F> {
  attachmentId?: number
  formData: F
  config: AxiosRequestConfig<F>
}

export class ServicesAttachment {
  async getAttachments<Result>({
    profissionalId,
    orphanId,
    institutionId,
    requestId,
    config,
  }: GetAttachments<any>): Promise<Result> {
    const url = `profissionais/${profissionalId}/instituicoes/${institutionId}/orfaos/${orphanId}/solicitacoes/${requestId}/documentos/`
    const response = await api.get<Result>(url, config)
    return response.data
  }

  async uploadAttachment({
    formData,
    config,
  }: AttachmentParams<FormData>): Promise<any> {
    const url = 'documentos/'
    const {
      data: { mensagem },
    } = await api.post(url, formData, config)
    return mensagem
  }

  async editAttachment({
    attachmentId,
    formData,
    config,
  }: AttachmentParams<FormData>): Promise<any> {
    const url = `documentos/${attachmentId}/atualizar/`
    const {
      data: { mensagem },
    } = await api.patch(url, formData, config)
    return mensagem
  }

  async submissionAttachments(requestId: string | undefined): Promise<any> {
    const url = `solicitacoes/${requestId}/confirmar-envio/`
    const {
      data: { mensagem },
    } = await api.patch(url, { status: 2 })
    return mensagem
  }

  async correctionAttachments(requestId: string | undefined): Promise<any> {
    const url = `solicitacoes/${requestId}/confirmar-correcao/`
    const {
      data: { mensagem },
    } = await api.patch(url, { status: 5 })
    return mensagem
  }
}
