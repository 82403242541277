import {
  Flex,
  Heading,
  Spinner,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AlertComponent,
  BreadCrumb,
  CircularProgressComponent,
  Content,
  Stepper,
} from '../../components'
import { useRequestFilteredById } from '../../data/contexts'
import { useGetRequestById } from '../../data/hooks'
import { TypeStatus } from '../../logic/core'
import { Card, Footer } from './components/'
import { Opinion } from './components/Opinion'
import { Template } from '../../components/Template/index'

export function FixRequest() {
  const {
    request: { status },
    ...getRequestById
  } = useGetRequestById()
  const getRequestFilteredById = useRequestFilteredById()
  const { institutionId, orphanId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (status !== '' && status !== TypeStatus.CORRECAO) {
      return navigate(`/orphans/${orphanId}/requests`)
    }
  }, [status, orphanId, navigate])

  const isMobileVersionLarge = useBreakpointValue({
    base: true,
    md: false,
  })

  document.title = 'RN-ACOLHE - Corrigir solicitação'

  return (
    <Content>
      <BreadCrumb
        previousPaths={[
          {
            name: 'Instituições',
            url: `/institutions`,
          },
          {
            name: 'Órfãos',
            url: `/institutions/${institutionId}/orphans`,
          },
          {
            name: 'Solicitações',
            url: `/institutions/${institutionId}/orphans/${orphanId}/requests`,
          },
        ]}
        currentPath="Corrigir solicitação"
      />

      <Heading mb="8" as="h1" size="lg" fontWeight="normal">
        Corrigir solicitação
      </Heading>

      {getRequestById.loading || getRequestFilteredById.loading ? (
        <Flex color="blue.300" justify="center" align="center">
          <Spinner />
        </Flex>
      ) : getRequestById.error ? (
        <AlertComponent
          status="error"
          title="Ocorreu um erro"
          description={getRequestById.error}
        />
      ) : getRequestFilteredById.error ? (
        <AlertComponent
          status="error"
          title="Ocorreu um erro"
          description={getRequestFilteredById.error}
        />
      ) : getRequestFilteredById.request?.anexos.length === 0 ? (
        <AlertComponent
          status="warning"
          title="Pedido de correção não encontrado"
          description={
            'Não encontramos nenhum pedido de correção de solicitação.'
          }
        />
      ) : (
        <>
          {status === TypeStatus.CORRECAO && (
            <>
              <Heading
                as="h3"
                mb="0.5rem"
                size="md"
                fontWeight="bold"
                textTransform="uppercase"
              >
                Parecer geral da solicitação
              </Heading>

              {getRequestFilteredById.request.parecer && (
                <Opinion text={getRequestFilteredById.request.parecer} />
              )}

              <Flex direction="column" gap="2.5rem">
                {isMobileVersionLarge ? (
                  <VStack>
                    <CircularProgressComponent
                      value={getRequestFilteredById.correctedAttachments}
                      maxPercent={getRequestFilteredById.allAttachments * 10}
                    />
                    <Text fontSize="1.25rem">
                      Documentos obrigatórios anexados:{' '}
                      <b>{getRequestFilteredById.correctedAttachments} </b>
                      de <b>{getRequestFilteredById.allAttachments}</b>
                    </Text>
                  </VStack>
                ) : (
                  <Template.Stepper
                    array={getRequestFilteredById.steps}
                    limit={getRequestFilteredById.allAttachments}
                  />
                )}

                <VStack spacing="1rem">
                  {getRequestFilteredById.request?.anexos.map(
                    (attachment, index) => (
                      <Card
                        key={attachment.id}
                        attachment={attachment}
                        index={index}
                      />
                    ),
                  )}
                </VStack>

                <Footer />
              </Flex>
            </>
          )}
        </>
      )}
    </Content>
  )
}
