import { useDisclosure } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Template } from '../../../components'
import { useRequestFilteredById } from '../../../data/contexts'
import { useCorrectionAttachments } from '../../../data/hooks'
import { queryClient } from '../../../services/queryClient'

export function Footer() {
  const { orphanId, institutionId } = useParams()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const getRequestFilteredById = useRequestFilteredById()
  const correctionAttachments = useCorrectionAttachments()

  function showErrors(error: any) {
    Object.entries(error).forEach(([_, value]) => {
      toast.error(`${value}`)
    })
  }

  useEffect(() => {
    if (correctionAttachments.success) {
      toast.success(correctionAttachments.success)
      queryClient.invalidateQueries('requests')
      navigate(`/institutions/${institutionId}/orphans/${orphanId}/requests`)
    }
    if (correctionAttachments.error) {
      toast.error(correctionAttachments.error)
      onClose()
    }
    if (correctionAttachments.errorData) {
      showErrors(correctionAttachments.errorData)
      onClose()
    }
  }, [correctionAttachments, orphanId, navigate, onClose, institutionId])

  const numberOfFilesNotAttached =
    getRequestFilteredById.allAttachments -
    getRequestFilteredById.correctedAttachments

  return (
    <>
      <Template.Modal
        title="Confirmar correção da solicitação!"
        children={
          'Após a confirmação, os documentos não poderão ser mais substituídos por você. Deseja realmente confirmar a correção da solicitação?'
        }
        onConfirm={correctionAttachments.execute}
        isLoading={correctionAttachments.loading}
        isOpen={isOpen}
        onClose={onClose}
      />

      <Template.DocumentFooter
        orphanId={orphanId}
        numberOfFilesNotAttached={numberOfFilesNotAttached}
        tooltipLabel={`Você precisa substituir ${numberOfFilesNotAttached} documento(s) obrigatório(s) para poder confirmar correção da solicitação.`}
        onOpen={onOpen}
      />
    </>
  )
}
