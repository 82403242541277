import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Error, services } from '../../../logic/core'
import {
  Orphan,
  OrphanData,
  OrphanFormData,
  OrphanFormValidatorSchema,
} from '../../../logic/core/orphan'
import { Errors } from '../../../logic/utils/Errors'
import { queryClient } from '../../../services/queryClient'
import { useAuth } from '../../contexts/AuthContext'

const { orphan } = services

export function useRegisterOrphan(institutionId: string | undefined) {
  const { user } = useAuth()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    watch,
    resetField,
    control,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<OrphanFormData>({
    resolver: yupResolver(OrphanFormValidatorSchema),
  })

  const watchFieldRecebeBeneficio = watch('recebe_beneficio')

  const isRecebeBeneficio = watchFieldRecebeBeneficio === 'S'

  useEffect(() => {
    if (!isRecebeBeneficio) {
      resetField('fonte_beneficio', { defaultValue: '' })
      resetField('valor_beneficio', { defaultValue: '' })
    }
  }, [isRecebeBeneficio, resetField])

  const createOrphan = useMutation(
    async (orphanData: OrphanData) => {
      const { data } = await orphan.create(orphanData)
      toast.success(`${data.mensagem}`)
    },
    { onSuccess, onError },
  )

  function onSuccess() {
    queryClient.invalidateQueries('orphans')
    navigate(`/institutions/${institutionId}/orphans`)
  }

  function onError(errorRegisterOrphan: any) {
    if (errorRegisterOrphan.response.status === 400) {
      const data = errorRegisterOrphan.response.data
      const mapErrors = Errors.mapErrors(data)

      if (mapErrors) {
        mapErrors.forEach(({ type, name, message }: Error) => {
          setError(name, { type, message })
        })
        toast.error(
          'Ocorreu um erro ao tentar cadastrar o órfão, verifique os campos em destaque!',
        )
      }
    } else {
      toast.error(
        'Ocorreu um erro ao tentar cadastrar o órfão, tente novamente mais tarde!',
      )
    }
  }

  const handleCreateUser: SubmitHandler<OrphanFormData> = async (
    values,
    event,
  ) => {
    event?.preventDefault()
    await new Promise((resolve) => setTimeout(resolve, 2000))
    const profissionalId = user?.profissional_id
    const orphanData = Orphan.formatOrphanData(
      values,
      profissionalId,
      institutionId,
    )

    await createOrphan.mutateAsync(orphanData)
  }

  return {
    Errors,
    control,
    errors,
    handleSubmit,
    handleCreateUser,
    isRecebeBeneficio,
    isSubmitting,
    register,
  }
}
