import { api } from '../../../services/axios'
import { AuthResponse } from './AuthResponse'
import { SignInCredentials } from './SignInCredentials'

export const ServicesLogin = {
  async login(credentials: SignInCredentials): Promise<AuthResponse> {
    const { data } = await api.post('login/', credentials)
    return data
  },
}
