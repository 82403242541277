import { SimpleGrid } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { Template, Pagination, BreadCrumb } from '../../components'
import { useGetOrphans } from '../../data/hooks'
import { useOrphansPageStore } from '../../data/store'
import { CardOrphan } from './components/CardOrphan'

const REGISTER_PER_PAGE = 9

export function OrphansList() {
  const { institutionId } = useParams()
  const { currentPage, setCurrentPage } = useOrphansPageStore()
  const { count, error, isFetching, isLoading, results } = useGetOrphans(
    REGISTER_PER_PAGE,
    currentPage,
    Number(institutionId),
  )
  const showSpinner = !isLoading && isFetching
  const showErrorMessage = !isLoading && error
  const showWarningMessage = !isLoading && !error && !results?.length
  const showResults = !!results && !error && !isLoading
  const showPagination =
    !!count && count > REGISTER_PER_PAGE && !!results?.length

  document.title = 'RN-ACOLHE - Órfãos'

  return (
    <Template.Content>
      <BreadCrumb
        previousPaths={[{ name: 'Instituições', url: '/institutions' }]}
        currentPath="Orfãos"
      />
      <Template.Heading
        pageTitle="Orfãos"
        linkTo={`create/`}
        showSpinner={showSpinner}
        textLink="Cadastrar Orfão"
      />
      {isLoading && <Template.Spinner />}
      {showErrorMessage && (
        <Template.Alert
          status="error"
          title="Ocorreu um erro"
          description="Não foi possível listar os órfãos."
        />
      )}
      {showWarningMessage && (
        <Template.Alert
          status="warning"
          title="Nenhum Órfão encontrado"
          description="Não encontramos nenhum órfão cadastrado. 
          Você pode cadastrar um novo órfão clicando no botão Cadastrar órfão.
          "
        />
      )}
      <SimpleGrid
        flex={'1'}
        gap={'4'}
        minChildWidth={{ base: 'fit-content', sm: '300px' }}
      >
        {showResults &&
          results?.map((orphan) => (
            <CardOrphan
              key={orphan.id}
              orphan={orphan}
              institutionId={Number(institutionId)}
            />
          ))}
      </SimpleGrid>

      {showPagination && (
        <Pagination
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          registersPerPage={REGISTER_PER_PAGE}
          titlePage="orfãos"
          totalCountOfRegisters={count}
        />
      )}
    </Template.Content>
  )
}
