import { Flex, FlexProps } from '@chakra-ui/react'

type FormWrapProps = FlexProps

export function FormWrap({ children, ...props }: FormWrapProps) {
  return (
    <Flex flexDirection="column" {...props}>
      {children}
    </Flex>
  )
}
