import { FlexProps } from '@chakra-ui/react'
import { RiAddLine } from 'react-icons/ri'
import { Heading } from '../Heading'

interface TemplateHeadingProps extends FlexProps {
  showSpinner?: boolean
  pageTitle?: string
  linkTo?: string
  textLink?: string
}

export function TemplateHeading({
  children,
  pageTitle,
  linkTo,
  showSpinner,
  textLink,
  ...props
}: TemplateHeadingProps) {
  return (
    <Heading.Root {...props}>
      <Heading.Title>
        {children ?? pageTitle}
        {showSpinner && <Heading.Spinner />}
      </Heading.Title>
      {!!linkTo && (
        <Heading.Link to={linkTo} icon={RiAddLine}>
          {textLink}
        </Heading.Link>
      )}
    </Heading.Root>
  )
}
