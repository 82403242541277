import { IconType } from 'react-icons'
import { Link, LinkProps } from 'react-router-dom'
import { Button } from '../Button'

interface HeadingLinkProps extends LinkProps {
  icon: IconType
}

export function HeadingLink({ icon, children, ...props }: HeadingLinkProps) {
  return (
    <Link {...props}>
      <Button.Secondary icon={icon} size="sm">
        {children}
      </Button.Secondary>
    </Link>
  )
}
