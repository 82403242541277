import { Divider, DividerProps } from '@chakra-ui/react'

type FormDividerProps = DividerProps

export function FormDivider({ ...rest }: FormDividerProps) {
  return (
    <Divider
      paddingTop="7px"
      borderBottom="1px dashed #797D9A"
      opacity="0.4"
      {...rest}
    />
  )
}
