import { FlexProps } from '@chakra-ui/react'
import { Form } from '../Form'

interface TemplateFieldsetProps extends FlexProps {
  legend: string
  span: string
}

export function TemplateFieldset({
  children,
  legend,
  span,
  ...props
}: TemplateFieldsetProps) {
  return (
    <Form.Fieldset {...props}>
      <Form.Wrap width="100%">
        <Form.Legend>{legend}</Form.Legend>
        <Form.Span>{span}</Form.Span>
        <Form.Divider />
      </Form.Wrap>
      {children}
    </Form.Fieldset>
  )
}
