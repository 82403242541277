import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import { queryClient } from '../../services/queryClient'

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

type User = {
  profissional_id: number
  nome: string
  cpf: string
}

type AuthContextData = {
  signIn: (access: string, refresh: string) => void
  signOut: () => void
  user?: User
  isAuthenticated: boolean
}

const AuthContext = createContext({} as AuthContextData)

type AuthProviderProps = {
  children: ReactNode
}

type Token = {
  token_type: string
  exp: number
  iat: number
  jti: string
  user_id: number
  profissional_id: number
  nome: string
  cpf: string
}

export function getToken(): string | undefined {
  return Cookies.get('accessToken')
}

export function getRefreshToken(): string | undefined {
  return Cookies.get('refreshToken')
}

export function jwtDecode(token: string): Readonly<Token> {
  return jwt_decode(token)
}

export function refreshTokenIsValid(): boolean {
  const refreshToken = getRefreshToken()
  if (!refreshToken) {
    return false
  }
  const { exp, iat } = jwtDecode(refreshToken)
  const now = new Date().getTime() / 1000
  return exp > now && iat < now
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User>()
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    const accessToken = getToken()
    const refreshToken = getRefreshToken()

    if (accessToken && refreshToken) {
      const { profissional_id, nome, cpf } = jwtDecode(accessToken)
      setUser({ profissional_id, nome, cpf })
      setIsAuthenticated(true)
    } else {
      signOut()
    }
  }, []) // eslint-disable-line

  async function signIn(access: string, refresh: string): Promise<void> {
    if (access && refresh) {
      const { profissional_id, nome, cpf } = jwtDecode(access)

      if (profissional_id) {
        Cookies.set('accessToken', access, {
          expires: 60 * 15, // 15 minutos
        })

        Cookies.set('refreshToken', refresh, {
          expires: 1, // 1 dia
        })

        setUser({ profissional_id, nome, cpf })
        setIsAuthenticated(true)
      }
    }
  }

  function signOut(): void {
    Cookies.remove('accessToken')
    Cookies.remove('refreshToken')

    queryClient.removeQueries()

    setIsAuthenticated(false)
    setUser(undefined)
  }

  return (
    <AuthContext.Provider value={{ signIn, signOut, user, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth deve ser usado em um AuthProvider')
  }

  return context
}
