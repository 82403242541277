import * as React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from './styles/theme'
import { SidebarDrawerProvider } from './data/contexts/SidebarDrawerContext'
import { Routes } from './routes/'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { queryClient } from './services/queryClient'
import { Toast } from './components'
import { AuthProvider } from './data/contexts/AuthContext'
import 'react-toastify/dist/ReactToastify.css'

const isDev = process.env.NODE_ENV === 'development'

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <Toast />
        <SidebarDrawerProvider>
          <Router>
            <Routes />
          </Router>
        </SidebarDrawerProvider>
      </ChakraProvider>
    </AuthProvider>
    {isDev && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>
)
