import { useDisclosure } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Template } from '../../../components'
import { useAttachments } from '../../../data/contexts/AttachmentsContext'
import { useSubmissionAttachments } from '../../../data/hooks'
import { queryClient } from '../../../services/queryClient'

export function Footer() {
  const { requiredAttachments } = useAttachments()
  const { orphanId, institutionId } = useParams()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const submissionAttachment = useSubmissionAttachments()

  function showErrors(error: any) {
    Object.entries(error).forEach(([_, value]) => {
      toast.error(`${value}`)
    })
  }

  useEffect(() => {
    if (submissionAttachment.success) {
      toast.success(submissionAttachment.success)
      queryClient.invalidateQueries('requests')
      navigate(`/institutions/${institutionId}/orphans/${orphanId}/requests/`)
    }
    if (submissionAttachment.error) {
      toast.error(submissionAttachment.error)
      onClose()
    }
    if (submissionAttachment.errorData) {
      showErrors(submissionAttachment.errorData)
      onClose()
    }
  }, [submissionAttachment, orphanId, institutionId, navigate, onClose])

  const numberOfFilesRequired = 9
  const numberOfFilesNotAttached = numberOfFilesRequired - requiredAttachments

  return (
    <>
      <Template.Modal
        title="Confirmar envio da documentação!"
        children={
          'Após a confirmação, os documentos não poderão ser mais substituídos por você. Deseja realmente confirmar o envio da documentação?'
        }
        onConfirm={submissionAttachment.execute}
        isLoading={submissionAttachment.loading}
        isOpen={isOpen}
        onClose={onClose}
      />

      <Template.DocumentFooter
        orphanId={orphanId}
        institutionId={institutionId}
        numberOfFilesNotAttached={numberOfFilesNotAttached}
        tooltipLabel={`Você precisa anexar ${numberOfFilesNotAttached} documento(s) obrigatório(s) para poder confirmar o envio da documentação.`}
        onOpen={onOpen}
      />
    </>
  )
}
