import { InstitutionState } from './'

export const INSTITUTION_INITIAL_STATE: InstitutionState = {
  loading: false,
  error: false,
  institutionOutput: {
    id: 0,
    nome: '',
    ativa: false,
    cnpj: '',
    email: '',
    telefone: '',
    natureza: '',
    modalidade: '',
    endereco: {
      id: 0,
      logradouro: '',
      numero: '',
      bairro: '',
      complemento: '',
      ponto_de_referencia: '',
      uf: '',
      cep: '',
      municipio: '',
    },
  },
}
