import { Divider, Flex, FlexProps } from '@chakra-ui/react'

interface CardFooterProps extends FlexProps {
  hasDivider?: boolean
}

export function CardFooter({
  children,
  hasDivider = true,
  ...rest
}: CardFooterProps) {
  return (
    <Flex
      as="footer"
      alignItems="center"
      flexDirection="column"
      justify="center"
      margin="0 1rem 1rem 1rem"
      {...rest}
    >
      {hasDivider && <Divider mb="1rem" borderColor="gray.700" />}
      {children}
    </Flex>
  )
}
