import { useReducer, useState } from 'react'
import { services } from '../../../logic/core'
import {
  ACTION_TYPES,
  attachFileReducer,
  INITIAL_STATE_ATTACH_FILE,
} from '../../reducers'

const ERROR_MESSAGES = {
  canceled: 'O envio do anexo foi cancelado pelo usuário.',
  default:
    'Ocorreu um erro no envio do anexo. Verifique o arquivo inserido e tente novamente.',
}

export function useAttachFile() {
  const [progress, setProgress] = useState(0)
  const [state, dispatch] = useReducer(
    attachFileReducer,
    INITIAL_STATE_ATTACH_FILE,
  )

  async function handleFileAttachment(
    formData: FormData,
    attachmentId?: number,
  ): Promise<void> {
    const controller = new AbortController()
    const { signal } = controller
    dispatch({ type: ACTION_TYPES.FETCH_START, abortController: controller })
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
        signal: signal,
      }
      setProgress(0)
      const interval = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 1)
      }, 20)
      await new Promise((resolve) => setTimeout(resolve, 2000)).finally(() => {
        clearInterval(interval)
      })
      if (attachmentId) {
        setProgress(100)
        const mensagem = await services.documents.editAttachment({
          attachmentId,
          formData,
          config,
        })
        dispatch({ type: ACTION_TYPES.FETCH_SUCCESS, success: `${mensagem}` })
      } else {
        const mensagem = await services.documents.uploadAttachment({
          formData,
          config,
        })
        setProgress(100)
        dispatch({ type: ACTION_TYPES.FETCH_SUCCESS, success: `${mensagem}` })
      }
    } catch (errorAttachDocument: any) {
      if (errorAttachDocument.message === 'canceled') {
        dispatch({
          type: ACTION_TYPES.FETCH_ERROR,
          error: ERROR_MESSAGES['canceled'],
          errorData: undefined,
        })
      } else if (errorAttachDocument?.response) {
        if (
          errorAttachDocument?.response.status === 400 ||
          errorAttachDocument?.response.status === 404
        ) {
          dispatch({
            type: ACTION_TYPES.FETCH_ERROR,
            errorData: errorAttachDocument?.response.data,
            error: undefined,
          })
        } else {
          dispatch({
            type: ACTION_TYPES.FETCH_ERROR,
            error: ERROR_MESSAGES['default'],
            errorData: undefined,
          })
        }
      } else {
        dispatch({
          type: ACTION_TYPES.FETCH_ERROR,
          error: ERROR_MESSAGES['default'],
          errorData: undefined,
        })
      }
    } finally {
      dispatch({ type: ACTION_TYPES.FETCH_FINALLY })
    }
  }

  function handleFileAttachmentAbort() {
    if (!state.abortController) return
    state.abortController.abort()
    setProgress(100)
  }

  return {
    ...state,
    handleFileAttachment,
    handleFileAttachmentAbort,
    progress,
  }
}
