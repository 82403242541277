import { useQuery } from 'react-query'
import { useAuth } from '../../contexts/AuthContext'
import { services } from '../../../logic/core'

const { orphan } = services
const THOUSAND_MILLISECONDS = 1000
const ONE_MINUTE = THOUSAND_MILLISECONDS * 60
const FIVE_MINUTES = ONE_MINUTE * 5

export function useGetOrphans(
  registerPerPage: number,
  currentPage: number,
  institutionId: number,
) {
  const { user } = useAuth()
  const profissionalId = user?.profissional_id

  const { data, error, isFetching, isLoading } = useQuery(
    ['orphans', currentPage, registerPerPage, profissionalId, institutionId],
    () =>
      orphan.get({
        registerPerPage,
        currentPage,
        profissionalId,
        institutionId,
      }),
    {
      staleTime: FIVE_MINUTES, // 5 minutes
      enabled: !!profissionalId && !!institutionId,
    },
  )

  return {
    error,
    isFetching,
    isLoading,
    results: data?.results,
    count: data?.count,
  }
}
