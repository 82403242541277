import { Text, TextProps } from '@chakra-ui/react'

type FormSpanProps = TextProps

export function FormSpan({ children, ...props }: FormSpanProps) {
  return (
    <Text
      as="span"
      fontSize="0.75rem"
      lineHeight="normal"
      color="gray.400"
      {...props}
    >
      {children}
    </Text>
  )
}
