import { AxiosRequestConfig } from 'axios'
import { api } from '../../../services/axios'
import { Params } from '../share'
import { RequestFormData } from './RequestFormData'

export interface ResponseRequest<T> {
  count: number
  results: T[]
}

interface GetRequest<C> {
  profissionalId: number | undefined
  institutionId: string | undefined
  orphanId: string | undefined
  requestId: string | undefined
  config?: AxiosRequestConfig<C>
}

export class ServicesRequest {
  async get(params: Params): Promise<ResponseRequest<RequestFormData>> {
    const {
      registerPerPage,
      currentPage,
      institutionId,
      orphanId,
      profissionalId,
    } = params
    const url = `profissionais/${profissionalId}/instituicoes/${institutionId}/orfaos/${orphanId}/solicitacoes/?limit=${registerPerPage}&offset=${
      (currentPage - 1) * registerPerPage
    }`
    const {
      data: { count, results },
    } = await api.get<ResponseRequest<RequestFormData>>(url)
    return { count, results }
  }

  async getFilteredById<Result>({
    profissionalId,
    institutionId,
    orphanId,
    requestId,
    config,
  }: GetRequest<any>): Promise<Result> {
    const url = `profissionais/${profissionalId}/instituicoes/${institutionId}/orfaos/${orphanId}/solicitacoes/?id=${requestId}`
    const { data } = await api.get<Result>(url, config)
    return data
  }

  async getById<Result>({
    profissionalId,
    institutionId,
    orphanId,
    requestId,
    config,
  }: GetRequest<any>): Promise<Result> {
    const url = `profissionais/${profissionalId}/instituicoes/${institutionId}/orfaos/${orphanId}/solicitacoes/${requestId}`
    const { data } = await api.get<Result>(url, config)
    return data
  }
}
