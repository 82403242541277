import { RiFileListLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { Button, Card } from '../../../components'
import { TypeOrphan } from '../../../logic/core/orphan'

import { CPF } from '../../../logic/utils/CPF'

interface CardOrphansProps {
  orphan: TypeOrphan
  institutionId: Number
  onPrefetchOrphans?: (orphanId: string) => void
}

export function CardOrphan({ orphan, institutionId }: CardOrphansProps) {
  return (
    <Card.Root>
      <Card.Content>
        <Card.Title>{orphan.nome}</Card.Title>
        <Card.Label>
          CPF:
          <Card.Info>{CPF.format(orphan.cpf)}</Card.Info>
        </Card.Label>
      </Card.Content>
      <Card.Footer>
        <Link to={`${orphan.id}/requests/`}>
          <Button.Primary icon={RiFileListLine} size="sm">
            Solicitações
          </Button.Primary>
        </Link>
      </Card.Footer>
    </Card.Root>
  )
}
