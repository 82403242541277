import { Flex, FlexProps } from '@chakra-ui/react'

type FormFieldsetProps = FlexProps

export function FormFieldset({ children, ...props }: FormFieldsetProps) {
  return (
    <Flex
      as="fieldset"
      flexDirection="column"
      gap="2rem"
      width="100%"
      {...props}
    >
      {children}
    </Flex>
  )
}
