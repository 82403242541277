import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { Button } from '../Button'
// import { SecondaryButton, TertiaryButton } from '..'

interface ModalCancelRegistrationProps {
  children: ReactNode
  confirmCancel: () => void
  isOpen: boolean
  onClose: () => void
}

export function ModalCancel({
  children,
  confirmCancel,
  isOpen,
  onClose,
}: ModalCancelRegistrationProps) {
  const modalSize = useBreakpointValue({ base: 'xs', md: 'sm', '3xl': 'lg' })
  return (
    <Modal
      isCentered
      size={modalSize}
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="scale"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'}>{children}</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={'center'}>
          Após a confirmação, os dados preenchidos serão perdidos. Deseja
          realmente confirmar o cancelamento?
        </ModalBody>

        <ModalFooter alignItems={'center'} justifyContent={'center'} gap={4}>
          <Button.Tertiary onClick={onClose}>Fechar</Button.Tertiary>
          <Button.Primary onClick={confirmCancel}>Confirmar</Button.Primary>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
