import { useQuery } from 'react-query'
import { services } from '../../../logic/core'

const { requests } = services
const THOUSAND_MILLISECONDS = 1000
const ONE_MINUTE = THOUSAND_MILLISECONDS * 60
const FIVE_MINUTES = ONE_MINUTE * 5

export const useGetRequest = (
  registerPerPage: number,
  currentPage: number,
  orphanId: number,
  institutionId: number,
  profissionalId?: number,
) => {
  const getRequest = useQuery(
    [
      `requests`,
      registerPerPage,
      currentPage,
      institutionId,
      orphanId,
      profissionalId,
    ],
    () =>
      requests.get({
        registerPerPage,
        currentPage,
        orphanId,
        institutionId,
        profissionalId,
      }),
    {
      staleTime: FIVE_MINUTES, // 5 minutes
      enabled: !!orphanId && !!institutionId && !!profissionalId,
    },
  )

  return { getRequest }
}
