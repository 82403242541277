import { useGetAttachments } from '../hooks'
import { createContext, useContext } from 'react'

interface AttachmentsContextProps {
  children: React.ReactNode
}

type AttachmentsContextData = ReturnType<typeof useGetAttachments>

export const AttachmentsContext = createContext({} as AttachmentsContextData)

export function AttachmentsProvider({ children }: AttachmentsContextProps) {
  const value = useGetAttachments()

  return (
    <AttachmentsContext.Provider value={value}>
      {children}
    </AttachmentsContext.Provider>
  )
}

export function useAttachments(): AttachmentsContextData {
  const context = useContext(AttachmentsContext)

  if (!context) {
    throw new Error('useAttachments deve ser usado em um AttachmentsProvider')
  }

  return context
}
