import { Text, TextProps } from '@chakra-ui/react'

type CardLabelProps = TextProps

export function CardLabel({ children, ...rest }: CardLabelProps) {
  return (
    <Text fontSize="1rem" fontWeight="bold" lineHeight="normal" {...rest}>
      {children}
    </Text>
  )
}
