import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react'

type AreaProps = SimpleGridProps

export function Area({ children, ...props }: AreaProps) {
  return (
    <SimpleGrid
      flex="1"
      gap="1rem"
      minChildWidth={{ base: 'fit-content', md: '320px' }}
      {...props}
    >
      {children}
    </SimpleGrid>
  )
}
