import { Alert, AlertProps } from '@chakra-ui/react'

type AlertContentProps = AlertProps

export function AlertRoot({ children, ...props }: AlertContentProps) {
  return (
    <Alert
      variant="solid"
      flexDirection="column"
      alignItems="center"
      padding="2rem"
      minHeight={{ base: 'fit-content', sm: '12.375rem' }}
      textAlign="center"
      borderRadius="0.5rem"
      gap="0.5rem"
      {...props}
    >
      {children}
    </Alert>
  )
}
