import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom'
import {
  AttachmentsProvider,
  GetRequestFilteredByIdProvider,
} from '../data/contexts'
import { Layout } from '../pages/Layout'
import { SignIn } from '../pages/SignIn'
import { OrphansList } from '../pages/OrphansList'
import { InstitutionsList } from '../pages/InstitutionsList'
import { CreateRequest } from '../pages/RequestsCreate'
import { RequestsList } from '../pages/RequestsList'
import { AttachDocumentation } from '../pages/AttachDocumentation'
import { refreshTokenIsValid } from '../data/contexts/AuthContext'
import { OrphanRegister } from '../pages/OrphanRegister'
import { FixRequest } from '../pages/FixRequest'
import { RedefinePassword } from '../pages/RedefinePassword'

interface PrivateRouteProps {
  children: React.ReactElement
  redirectTo: string
}

function PrivateRoute({ children, redirectTo }: PrivateRouteProps) {
  return refreshTokenIsValid() ? children : <Navigate to={redirectTo} />
}

export function Routes() {
  return (
    <ReactRoutes>
      <Route index element={<SignIn />} />
      <Route path="redefine-password" element={<RedefinePassword />} />
      <Route />
      <Route
        path="institutions"
        element={
          <PrivateRoute redirectTo="/">
            <Layout />
          </PrivateRoute>
        }
      >
        <Route index element={<InstitutionsList />} />
        <Route path=":institutionId/orphans/" element={<OrphansList />} />
        <Route
          path=":institutionId/orphans/create/"
          element={<OrphanRegister />}
        />
        <Route
          path=":institutionId/orphans/:orphanId/requests/"
          element={<RequestsList />}
        />
        <Route
          path=":institutionId/orphans/:orphanId/requests/create"
          element={<CreateRequest />}
        />
        <Route
          path=":institutionId/orphans/:orphanId/requests/:requestId/documents"
          element={
            <AttachmentsProvider>
              <AttachDocumentation />
            </AttachmentsProvider>
          }
        />
        <Route
          path=":institutionId/orphans/:orphanId/requests/:requestId/fix"
          element={
            <GetRequestFilteredByIdProvider>
              <FixRequest />
            </GetRequestFilteredByIdProvider>
          }
        />
        <Route path="*" element={<InstitutionsList />} />
      </Route>
      <Route path="*" element={<SignIn />} />
    </ReactRoutes>
  )
}
