import { Link } from 'react-router-dom'
import { Button, Card } from '../../../components'
import { TypeInstitution } from '../../../logic/core/institution'
import { RiOpenArmLine } from 'react-icons/ri'

interface CardInstitutionProps {
  institution: TypeInstitution
}

export function CardInstitution(props: CardInstitutionProps) {
  return (
    <Card.Root>
      <Card.Content>
        <Card.Title>{props.institution.nome}</Card.Title>
        <Card.Label>
          Município:<Card.Info>{props.institution.municipio}</Card.Info>
        </Card.Label>
      </Card.Content>
      <Card.Footer>
        <Link to={`${props.institution.id}/orphans/`}>
          <Button.Primary icon={RiOpenArmLine} size="sm">
            Órfãos
          </Button.Primary>
        </Link>
      </Card.Footer>
    </Card.Root>
  )
}
