import { Flex, FlexProps, Spinner, SpinnerProps } from '@chakra-ui/react'

type TemplateSpinnerProps = FlexProps & SpinnerProps

export function TemplateSpinner(props: TemplateSpinnerProps) {
  return (
    <Flex justifyContent="center" alignItems="center" {...props}>
      <Spinner color="blue.300" {...props} />
    </Flex>
  )
}
