import * as yup from 'yup'

export const InstitutionFormValidatorSchema = yup.object().shape({
  logradouro: yup
    .string()
    .required('Logradouro é obrigatório')
    .max(50, 'Logradouro deve ter no máximo 50 caracteres'),
  cnpj: yup.string().transform((value) => value.replace(/\D/g, '')),
  numero: yup
    .string()
    .required('Número é obrigatório')
    .min(1, 'Número deve ter no mínimo 1 caracter')
    .max(10, 'Número deve ter no máximo 4 caracteres'),
  bairro: yup
    .string()
    .required('Bairro é obrigatório')
    .min(1, 'Bairro deve ter no mínimo 1 caracter')
    .max(50, 'Bairro deve ter no máximo 50 caracteres'),
  complemento: yup
    .string()
    .max(50, 'Complemento deve ter no máximo 3 caracteres')
    .optional(),
  ponto_de_referencia: yup
    .string()
    .max(50, 'Ponto de referência deve ter no máximo 3 caracteres')
    .optional(),
  cep: yup
    .string()
    .required('CEP é obrigatório')
    .transform((value) => value.replace(/\D/g, ''))
    .matches(/^[0-9]+$/, 'CEP deve conter apenas números')
    .min(8, 'CEP deve ter no mínimo 8 caracteres')
    .max(8, 'CEP deve ter no máximo 8 caracteres'),
  municipio: yup
    .string()
    .required('Município é obrigatório')
    .transform((value) => value.replace(/\D/g, '')),
  nome: yup.string().required('Nome da instituição é obrigatório').uppercase(),
  email: yup.string().required('Email é obrigatório'),
  telefone: yup
    .string()
    .required('Telefone é obrigatório')
    .transform((value) => value.replace(/[^0-9]/g, ''))
    .min(9, 'Telefone deve ter no mínimo 9 caracteres')
    .max(11, 'Telefone deve ter no máximo 11 caracteres'),
  natureza: yup.string().required('Natureza é obrigatório').nullable(),
  modalidade: yup.string().required('Modalidade é obrigatório'),
})
