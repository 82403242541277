import { Area, Pagination, Template } from '../../components'
import { useInstitutions } from '../../data/hooks'
import { useInstitutionsPageStore } from '../../data/store'
import { CardInstitution } from './components'

const REGISTER_PER_PAGE = 9

export function InstitutionsList() {
  const { currentPage, setCurrentPage } = useInstitutionsPageStore()
  const { count, error, isFetching, isLoading, results } = useInstitutions(
    currentPage,
    REGISTER_PER_PAGE,
  )
  const showSpinner = !isLoading && isFetching
  const showErrorMessage = !isLoading && error
  const showWarningMessage = !isLoading && !error && !results?.length
  const showResults = !!results && !error && !isLoading
  const showPagination =
    !!count && count > REGISTER_PER_PAGE && !!results?.length
  document.title = 'RN-ACOLHE - Instituições'
  return (
    <Template.Content>
      <Template.Heading pageTitle="Instituições" showSpinner={showSpinner} />
      {isLoading && <Template.Spinner />}
      {showErrorMessage && (
        <Template.Alert
          status="error"
          title="Ocorreu um erro"
          description="Não foi possível listar as instituições"
        />
      )}
      {showWarningMessage && (
        <Template.Alert
          status="warning"
          title="Nenhum Agente CRAS encontrado"
          description="Não encontramos nenhuma instituição cadastrada.
          "
        />
      )}
      <Area>
        {showResults &&
          results.map((institution) => (
            <CardInstitution key={institution.id} institution={institution} />
          ))}
      </Area>
      {showPagination && (
        <Pagination
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          registersPerPage={REGISTER_PER_PAGE}
          titlePage="instituições"
          totalCountOfRegisters={count}
        />
      )}
    </Template.Content>
  )
}
