import { Heading, HeadingProps } from '@chakra-ui/react'

type HeadingTitleProps = HeadingProps

export function HeadingTitle({ children, ...props }: HeadingTitleProps) {
  return (
    <Heading size="lg" fontWeight="normal" {...props}>
      {children}
    </Heading>
  )
}
