import {
  Flex,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BreadCrumb,
  Form,
  FormControlItem,
  Input,
  Select,
  Template,
} from '../../components'
import { benefits } from '../../data/constants'
import { useRegisterOrphan } from '../../data/hooks'
import { Coin } from '../../logic/utils'

export function OrphanRegister() {
  const { institutionId } = useParams()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  function handleConfirmCancel() {
    onClose()
    navigate(`/institutions/${institutionId}/orphans`)
  }

  const {
    control,
    errors,
    handleSubmit,
    handleCreateUser,
    isRecebeBeneficio,
    isSubmitting,
    register,
  } = useRegisterOrphan(institutionId)

  document.title = 'RN-ACOLHE - Cadastrar órfão'

  return (
    <Template.Content as={'form'} onSubmit={handleSubmit(handleCreateUser)}>
      <Template.CancelModal
        isOpen={isOpen}
        onClose={onClose}
        title="Cancelar cadastro de Órfão!"
        children="Após a confirmação, os dados preenchidos serão perdidos. Deseja realmente confirmar o cancelamento?"
        confirmCancellation={handleConfirmCancel}
      />

      <BreadCrumb
        previousPaths={[
          { name: 'Instituições', url: '/institutions' },
          { name: 'Órfãos', url: `/institutions/${institutionId}/orphans` },
        ]}
        currentPath="Cadastrar órfão"
      />

      <Template.Heading pageTitle="Cadastrar Órfão" />

      <Form.Wrap>
        <Template.Fieldset
          marginBottom="3rem"
          legend="Dados de pessoais"
          span="Dados pessoais do órfão"
        >
          <Grid
            templateAreas={{
              base: `"nome" "cpf"`,
              sm: `"nome cpf"`,
            }}
            gridTemplateColumns={{
              base: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: '65.887% 1fr',
            }}
            gap={'2rem'}
            marginBottom={'2rem'}
            w={'100%'}
          >
            <GridItem area={'nome'}>
              <Input
                label="Nome completo"
                type={'text'}
                {...register('nome')}
                error={errors.nome}
              />
            </GridItem>

            <GridItem area={'cpf'}>
              <Input
                label="CPF"
                type={'text'}
                mask={'999.999.999-99'}
                placeholder="000.000.000-00"
                {...register('cpf')}
                error={errors.cpf}
              />
            </GridItem>
          </Grid>

          <Grid
            templateAreas={{
              base: `"genero" "data-nascimento" "nis"`,
              sm: `"genero genero" "data-nascimento nis"`,
              md: `"genero data-nascimento nis"`,
            }}
            gridTemplateColumns={{
              base: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
            }}
            gap={'2rem'}
            w={'100%'}
          >
            <GridItem area={'genero'}>
              <FormControlItem
                _required
                label="Gênero"
                {...register('sexo')}
                error={errors.sexo}
              >
                <RadioGroup>
                  <Flex h={'2.5rem'} gap={'1rem'}>
                    <Radio value={'1'} {...register('sexo')} minW="115px">
                      Feminino
                    </Radio>
                    <Radio value={'2'} {...register('sexo')} minW="115px">
                      Masculino
                    </Radio>
                  </Flex>
                </RadioGroup>
              </FormControlItem>
            </GridItem>

            <GridItem area={'data-nascimento'}>
              <Input
                label="Data de nascimento"
                type={'date'}
                {...register('data_nascimento')}
                error={errors.data_nascimento}
              />
            </GridItem>

            <GridItem area={'nis'}>
              <Input
                label="NIS"
                type={'text'}
                mask={'999.99999.99-9'}
                placeholder="000.00000.00-0"
                {...register('nis')}
                error={errors.nis}
                // w={{base: '100%', sm: '47.224%', md: '100%'}}
              />
            </GridItem>
          </Grid>
        </Template.Fieldset>

        <Template.Fieldset
          marginBottom="3rem"
          legend="Fonte de renda"
          span="Fonte de renda do órfão"
        >
          <Grid
            templateAreas={{
              base: `"recebe-beneficio" "fonte-beneficio" "valor-beneficio"`,
              sm: `"recebe-beneficio recebe-beneficio" "fonte-beneficio valor-beneficio"`,
              md: `"recebe-beneficio fonte-beneficio valor-beneficio"`,
            }}
            gridTemplateColumns={{
              base: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
            }}
            gap={'2rem'}
            w={'100%'}
          >
            <GridItem area={'recebe-beneficio'}>
              <FormControlItem
                _required
                label="Recebe benefício"
                {...register('recebe_beneficio')}
                error={errors.recebe_beneficio}
              >
                <RadioGroup>
                  <Flex h={'2.5rem'} gap={'1rem'}>
                    <Radio
                      value={'S'}
                      {...register('recebe_beneficio')}
                      minW="115px"
                    >
                      Sim
                    </Radio>
                    <Radio
                      value={'N'}
                      {...register('recebe_beneficio')}
                      minW="115px"
                    >
                      Não
                    </Radio>
                  </Flex>
                </RadioGroup>
              </FormControlItem>
            </GridItem>

            <GridItem area={'fonte-beneficio'}>
              <Select
                label="Fonte de benefício"
                _required={isRecebeBeneficio}
                {...register('fonte_beneficio')}
                error={errors.fonte_beneficio}
                disabled={!isRecebeBeneficio}
              >
                <option value="">--Selecione--</option>
                {benefits.map((benefit) => (
                  <option key={benefit.id} value={benefit.id}>
                    {benefit.name}
                  </option>
                ))}
              </Select>
            </GridItem>

            <GridItem area={'valor-beneficio'}>
              <Controller
                control={control}
                name="valor_beneficio"
                defaultValue=""
                render={({ field }) => (
                  <Input
                    as={NumberFormat}
                    label="Valor do benefício"
                    placeholder="R$ 000,00"
                    _required={isRecebeBeneficio}
                    type={'text'}
                    disabled={!isRecebeBeneficio}
                    {...register('valor_beneficio')}
                    error={errors.valor_beneficio}
                    format={Coin.format}
                    isAllowed={Coin.validate}
                    {...field}
                  />
                )}
              />
            </GridItem>
          </Grid>
        </Template.Fieldset>

        <Template.Footer
          isLoading={isSubmitting}
          loadingText="Salvando"
          openCancelModal={onOpen}
        />
      </Form.Wrap>
    </Template.Content>
  )
}
