import { Flex, Stack, useDisclosure } from '@chakra-ui/react'
import { Navigate, useNavigate } from 'react-router-dom'
import {
  Input,
  ModalCancel,
  Password,
  RNAcolhe,
  RNSethas,
  Button,
  SubTitle,
  Title,
} from '../../components'
import { useAuth } from '../../data/contexts'
import { useRedefinePassword } from '../../data/hooks/useRedefinePassword'

export function RedefinePassword() {
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  function handleConfirmCancel() {
    onClose()
    navigate('/')
  }

  const {
    formStateRequestCode,
    formStateRedefinePassword,
    handleRequestCode,
    handleRedefinePassword,
    handleSubmitRequestCode,
    handleSubmitRedefinePassword,
    registerRequestCode,
    registerRedefinePassword,
    watchRequestCode,
    formRef,
    showFormCode,
    showFormPassword,
  } = useRedefinePassword()

  document.title = 'RN-ACOLHE - Redefinir senha'

  if (isAuthenticated) {
    return <Navigate to="/orphans" replace={true} />
  }
  return (
    <Flex
      height={{ base: '100%', '2xl': '100vh' }}
      width={'100%'}
      flexDirection={'row'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {showFormCode.current && (
        <Flex
          width={{ base: '100%', lg: '28.75rem' }}
          minH={'31.25rem'}
          height={{
            base: '100vh',
            lg: '37.5rem',
          }}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
          py={{ base: '1rem', md: '2rem' }}
          bg={'platinum.100'}
          borderRadius={{ base: '0rem', lg: '0.5rem' }}
        >
          <RNAcolhe />

          <Stack
            as="main"
            width={{ base: '100%', sm: '17.4375rem' }}
            px={{ base: '1.25rem', sm: '0rem' }}
            gap={{ base: '.75rem', sm: '1.75rem' }}
          >
            <Flex align={'start'} flexDir={'column'}>
              <Title>Redefinir Senha</Title>
              <SubTitle>Por favor, insira seu CPF.</SubTitle>
            </Flex>

            <Stack
              as="form"
              mt={'0px'}
              ref={formRef}
              spacing={'0.75rem'}
              onSubmit={handleSubmitRequestCode(handleRequestCode)}
            >
              <Input
                label={'CPF'}
                type={'text'}
                mask={'999.999.999-99'}
                placeholder="000.000.000-00"
                error={formStateRequestCode.errors.cpf}
                {...registerRequestCode('cpf')}
              />

              <Button.Secondary
                type={'submit'}
                isLoading={formStateRequestCode.isSubmitting}
                loadingText={'Solicitando'}
              >
                Solicitar
              </Button.Secondary>

              <Button.Tertiary
                onClick={() => {
                  navigate('/')
                }}
              >
                Cancelar
              </Button.Tertiary>
            </Stack>
          </Stack>

          <RNSethas />
        </Flex>
      )}

      {showFormPassword.current && (
        <Flex
          width={{ base: '100%', lg: '28.75rem' }}
          minH={'47rem'}
          height={{
            base: '100vh',
            lg: '55.3125rem',
            xl: '47rem',
            '3xl': '55.3125rem',
          }}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
          py={{ base: '1rem', md: '2rem' }}
          bg={'platinum.100'}
          borderRadius={{ base: '0rem', lg: '0.5rem' }}
        >
          <ModalCancel
            isOpen={isOpen}
            onClose={onClose}
            confirmCancel={handleConfirmCancel}
          >
            Cancelar a redefinição de senha!
          </ModalCancel>
          <RNAcolhe />

          <Stack
            as="main"
            width={{ base: '100%', sm: '17.4375rem' }}
            px={{ base: '1.25rem', sm: '0rem' }}
            gap={{ base: '.75rem', sm: '1.75rem' }}
          >
            <Flex align={'start'} flexDir={'column'}>
              <Title>Redefinir Senha</Title>
              <SubTitle>
                Por favor, insira o código, senha e confirme a senha.
              </SubTitle>
            </Flex>

            <Stack
              as="form"
              mt={'0px'}
              ref={formRef}
              spacing={'0.75rem'}
              onSubmit={handleSubmitRedefinePassword(handleRedefinePassword)}
            >
              <Input
                disabled
                label={'CPF'}
                type={'text'}
                mask={'999.999.999-99'}
                placeholder="000.000.000-00"
                value={watchRequestCode('cpf')}
                error={formStateRedefinePassword.errors.cpf}
                {...registerRedefinePassword('cpf')}
              />

              <Input
                label={'Código'}
                type={'text'}
                placeholder="Código"
                error={formStateRedefinePassword.errors.codigo}
                {...registerRedefinePassword('codigo')}
              />

              <Password
                label={'Nova senha'}
                placeholder="Digite sua nova senha"
                error={formStateRedefinePassword.errors.nova_senha}
                {...registerRedefinePassword('nova_senha')}
              />
              <Password
                label={'Confirmar senha'}
                placeholder="Confirme sua nova senha"
                error={formStateRedefinePassword.errors.confirmar_nova_senha}
                {...registerRedefinePassword('confirmar_nova_senha')}
              />

              <Button.Secondary
                type={'submit'}
                isLoading={formStateRedefinePassword.isSubmitting}
                loadingText={'Confirmando'}
              >
                Confirmar
              </Button.Secondary>

              <Button.Tertiary onClick={onOpen}>Cancelar</Button.Tertiary>
            </Stack>
          </Stack>

          <RNSethas />
        </Flex>
      )}
    </Flex>
  )
}
