import { TemplateAlert } from './TemplateAlert'
import { TemplateCancelModal } from './TemplateCancelModal'
import { TemplateConfirmRequestBindingModal } from './TemplateConfirmRequestBindingModal'
import { TemplateContent } from './TemplateContent'
import { TemplateFieldset } from './TemplateFieldset'
import { TemplateFooter } from './TemplateFooter'
import { TemplateHeading } from './TemplateHeading'
import { TemplateSpinner } from './TemplateSpinner'
import { TemplateDocumentFooter } from './TemplateDocumentFooter'
import { TemplateModal } from './TemplateModal'
import { TemplateStepper } from './TemplateStepper'

export const Template = {
  Alert: TemplateAlert,
  CancelModal: TemplateCancelModal,
  ConfirmRequestBindingModal: TemplateConfirmRequestBindingModal,
  Content: TemplateContent,
  Fieldset: TemplateFieldset,
  Footer: TemplateFooter,
  Heading: TemplateHeading,
  Spinner: TemplateSpinner,
  DocumentFooter: TemplateDocumentFooter,
  Modal: TemplateModal,
  Stepper: TemplateStepper,
}
