import { Flex } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IoIosSwap } from 'react-icons/io'
import { toast } from 'react-toastify'
import {
  ButtonOutline,
  CardDocument,
  FormDocument as Form,
  InputFile,
} from '../../../components'
import { useRequestFilteredById } from '../../../data/contexts'
import { Anexo, useAttachFile } from '../../../data/hooks'
import {
  AttachmentFormData,
  AttachmentFormValidatorSchema,
} from '../../../logic/core'
import { api } from '../../../services/axios'

interface CardProps {
  index: number
  attachment: Anexo
}

export function Card({ index, attachment }: CardProps) {
  const getRequestFilteredById = useRequestFilteredById()
  const attachFile = useAttachFile()
  const {
    trigger,
    setValue,
    formState: { errors },
  } = useForm<AttachmentFormData>({
    resolver: yupResolver(AttachmentFormValidatorSchema),
  })
  const [nameFile, setNameFile] = useState<string>('')
  const pathUrl = api.defaults.baseURL?.replace('/api/', '')
  const formRef = useRef<any>(null)

  async function submit(formData: FormData) {
    if (formData) {
      await attachFile.handleFileAttachment(formData, attachment.id)
    }
  }

  async function handleFileChange(e: any) {
    setNameFile(e.target.files[0].name)
    setValue('arquivo', e.target.files)
    const valid = await trigger('arquivo')
    if (!errors['arquivo'] && valid) {
      const formData = new FormData()
      formData.append('arquivo', e.target.files[0])
      await submit(formData)
    }
  }

  function showErrors(error: any) {
    Object.entries(error).forEach(([_, value]) => {
      toast.error(`${value}`)
    })
  }

  useEffect(() => {
    if (attachFile.success) {
      toast.success(attachFile.success)
      getRequestFilteredById.execute()
    }
    if (attachFile.error) toast.error(attachFile.error)
    if (attachFile.errorData) showErrors(attachFile.errorData)
  }, [attachFile, getRequestFilteredById])

  return (
    <CardDocument.Wrap>
      <Flex
        minWidth={{ base: '100%', sm: '1rem' }}
        minHeight={{ base: '1rem', sm: '100%' }}
        borderRadius={{ base: '8px 8px 0 0', sm: '8px 0 0 8px' }}
        bgColor={attachment.valido === false ? 'red.500' : 'green.50'}
      />
      <CardDocument.Root boxShadow="" borderRadius="0">
        <CardDocument.Label>
          <CardDocument.Number>{index + 1}</CardDocument.Number>
          <Flex
            width="100%"
            direction="column"
            gap={{ base: '1rem', lg: '1.5rem', xl: '1.5rem' }}
          >
            <CardDocument.Name>
              {attachment.tipo}
              <CardDocument.RequiredField>*</CardDocument.RequiredField>
            </CardDocument.Name>
            {attachment.justificativa && (
              <CardDocument.Justification text={attachment.justificativa} />
            )}
          </Flex>
        </CardDocument.Label>
        <CardDocument.Content>
          <Form.Root<Anexo> as="form" formRef={formRef} attachment={attachment}>
            {attachFile.loading ? (
              <Form.CancelSubmission
                nameFile={nameFile}
                label={attachment.tipo}
                progress={attachFile.progress}
                handleCancelRequest={attachFile.handleFileAttachmentAbort}
              />
            ) : (
              <>
                {!errors.arquivo && (
                  <Form.Link href={`${pathUrl}${attachment?.arquivo}`}>
                    {attachment.tipo}
                  </Form.Link>
                )}
                <Form.Input errors={errors.arquivo}>
                  <InputFile
                    display="none"
                    error={errors.arquivo}
                    onChange={handleFileChange}
                  />

                  <ButtonOutline.Blue
                    as="div"
                    icon={IoIosSwap}
                    minWidth="8.125rem"
                  >
                    Substituir
                  </ButtonOutline.Blue>
                </Form.Input>
              </>
            )}
          </Form.Root>
        </CardDocument.Content>
      </CardDocument.Root>
    </CardDocument.Wrap>
  )
}
