import { HeadingLink } from './HeadingLink'
import { HeadingRoot } from './HeadingRoot'
import { HeadingSpinner } from './HeadingSpinner'
import { HeadingTitle } from './HeadingTitle'

export const Heading = {
  Link: HeadingLink,
  Root: HeadingRoot,
  Spinner: HeadingSpinner,
  Title: HeadingTitle,
}
