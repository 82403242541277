import { create } from 'zustand'

interface StateProps {
  currentPage: number
  setCurrentPage: (currentPage: number) => void
}
 
export const useInstitutionsPageStore = create<StateProps>((set) => ({
  currentPage: 1,
  setCurrentPage: (currentPage: number) => set({ currentPage }),
}))
