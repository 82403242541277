import { Flex, FlexProps } from '@chakra-ui/react'

type FormFooterProps = FlexProps

export function FormFooter({ children, ...props }: FormFooterProps) {
  return (
    <Flex
      as="footer"
      justifyContent="flex-end"
      alignItems="center"
      gap="0.5rem"
      {...props}
    >
      {children}
    </Flex>
  )
}
