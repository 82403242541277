import {
  Flex,
  Heading,
  Spinner,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AlertComponent,
  BreadCrumb,
  CircularProgressComponent,
  Content,
  Template,
} from '../../components'
import { listOfAttachments } from '../../data/constants'
import { useAttachments } from '../../data/contexts'
import { Attachment, useGetRequestById } from '../../data/hooks'
import { TypeStatus } from '../../logic/core'
import { Card, Footer } from './components'

export function AttachDocumentation() {
  const {
    request: { status },
    ...getRequestById
  } = useGetRequestById()
  const getAttachments = useAttachments()
  const { orphanId, institutionId } = useParams()
  const navigate = useNavigate()
  const isMobileVersionLarge = useBreakpointValue({
    base: true,
    md: false,
  })

  useEffect(() => {
    if (status !== '' && status !== TypeStatus.PENDENTE) {
      navigate(`/institutions/${institutionId}/orphans/${orphanId}/requests/`)
    }
  }, [status, orphanId, institutionId, navigate])

  function attachmentFindByType(type: number, attachments: Attachment[]) {
    return attachments?.find((element): boolean => element.tipo === type)
  }

  document.title = 'RN-ACOLHE - Anexar documentação'

  return (
    <Content>
      <BreadCrumb
        previousPaths={[
          { name: 'Instituições', url: '/institutions' },
          {
            name: 'Órfãos',
            url: `/institutions/${institutionId}/orphans/`,
          },
          {
            name: 'Solicitações',
            url: `/institutions/${institutionId}/orphans/${orphanId}/requests`,
          },
        ]}
        currentPath="Anexar documentação"
      />

      <Heading mb={'2.5rem'} mt={'0.5rem'} size={'lg'} fontWeight={'normal'}>
        Anexar documentação
      </Heading>

      {getAttachments.loading || getRequestById.loading ? (
        <Flex color={'blue.300'} justify={'center'} align={'center'}>
          <Spinner />
        </Flex>
      ) : getRequestById.error ? (
        <AlertComponent
          status="error"
          title="Ocorreu um erro"
          description={getRequestById.error}
        />
      ) : getAttachments.error ? (
        <AlertComponent
          status="error"
          title="Ocorreu um erro"
          description={getAttachments.error}
        />
      ) : (
        <>
          {status === TypeStatus.PENDENTE && (
            <Flex direction={'column'} gap={'3rem'}>
              {isMobileVersionLarge ? (
                <VStack>
                  <CircularProgressComponent
                    value={getAttachments.requiredAttachments}
                    maxPercent={90}
                  />
                  <Text fontSize={'1.25rem'}>
                    Documentos obrigatórios anexados:{' '}
                    <b>{getAttachments.requiredAttachments} </b>
                    de <b>9</b>
                  </Text>
                </VStack>
              ) : (
                <Template.Stepper
                  array={getAttachments.attachedFiles}
                  limit={10}
                />
              )}

              <VStack spacing="1rem">
                {listOfAttachments.map((attachment) => (
                  <Card
                    key={attachment.id}
                    infoCard={attachment}
                    attachment={attachmentFindByType(
                      attachment.type,
                      getAttachments.attachments,
                    )}
                  />
                ))}
              </VStack>

              <Footer />
            </Flex>
          )}
        </>
      )}
    </Content>
  )
}
