import { Coin } from '../../utils'
import { OrphanData, OrphanFormData } from './'

export class Orphan {
  static formatOrphanData(
    values: OrphanFormData,
    profissionalId: number | undefined,
    institutionId: string | undefined,
  ) {
    const orphanData: OrphanData = {
      nome: values.nome,
      cpf: values.cpf,
      nis: values.nis,
      sexo: Number(values.sexo),
      data_nascimento: values.data_nascimento,
      recebe_beneficio: values.recebe_beneficio !== 'N',

      fonte_beneficio: values.fonte_beneficio
        ? Number(values.fonte_beneficio)
        : null,

      valor_beneficio: values.valor_beneficio
        ? Coin.unFormat(values.valor_beneficio)
        : null,

      tutor_responsavel: null,
      instituicao_responsavel: Number(institutionId),
    }
    return orphanData
  }
}
