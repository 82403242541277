import { Flex, Link as ChakraLink, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

type PreviousPath = {
  name: string
  url: string
}

interface BreadCrumbProps {
  previousPaths?: PreviousPath[]
  currentPath: string
}

export function BreadCrumb({ previousPaths, currentPath }: BreadCrumbProps) {
  return (
    <Flex flexFlow={'wrap'}>
      {previousPaths?.map((path: PreviousPath, index) => (
        <Flex key={index}>
          <ChakraLink>
            <Link to={`${path.url}`}>
              <Text
                _hover={{
                  color: 'blue.400',
                }}
                pb={2}
              >
                {path.name}
              </Text>
            </Link>
          </ChakraLink>
          <Text pb={2} px={3}>
            &gt;
          </Text>
        </Flex>
      ))}
      <Text fontWeight={'semibold'} pb={2} color={'blue.400'}>
        {currentPath}
      </Text>
    </Flex>
  )
}
