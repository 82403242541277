import { Button, ButtonProps, Icon } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { RiCheckboxCircleLine } from 'react-icons/ri'

interface ButtonPrimaryProps extends ButtonProps {
  icon?: IconType
}

export function ButtonPrimary({
  children,
  icon = RiCheckboxCircleLine,
  ...rest
}: ButtonPrimaryProps) {
  return (
    <Button
      _hover={{ bgColor: 'blue.200' }}
      bgColor="blue.50"
      color="white"
      leftIcon={<Icon as={icon} />}
      rounded={'0.25rem'}
      size="md"
      {...rest}
    >
      {children}
    </Button>
  )
}
