import { useQuery } from 'react-query'
import { useAuth } from '../../contexts/AuthContext'
import { services } from '../../../logic/core'

const THOUSAND_MILLISECONDS = 1000
const ONE_MINUTE = THOUSAND_MILLISECONDS * 60
const FIVE_MINUTES = ONE_MINUTE * 5

export function useInstitutions(currentPage = 1, registerPerPage = 9) {
  const { user } = useAuth()
  const profissionalId = user?.profissional_id
  const { institution } = services

  const { data, error, isFetching, isLoading } = useQuery(
    ['institutions', currentPage, registerPerPage, profissionalId],
    () => institution.get({ currentPage, registerPerPage, profissionalId }),
    {
      staleTime: FIVE_MINUTES,
      enabled: !!profissionalId,
    },
  )

  return {
    error,
    isFetching,
    isLoading,
    results: data?.results,
    count: data?.count,
  }
}
