import { Box, Flex, useBreakpointValue } from '@chakra-ui/react'
import { Header } from '../components/Header'
import { Sidebar } from '../components/Sidebar'
import { Outlet } from 'react-router-dom'

export function Layout() {
  const isWideVersion = useBreakpointValue({
    base: false,
    md: true
  })
  
  return (
    <Box>
      <Header />

      <Flex
        w={'100%'}
        mt={'6'}
        mb={isWideVersion ? 6 : 0}
        maxWidth={1480} 
        minHeight={isWideVersion ? 0 : 'calc(100vh - 100px)'}
        mx={'auto'}
        px={isWideVersion ? 6 : 0}
      >
        <Sidebar />
        <Outlet />
      </Flex>
    </Box>
  )
}
