import {
  AlertDescription as ChakraAlertDescription,
  AlertDescriptionProps,
} from '@chakra-ui/react'

type IAlertDescriptionProps = AlertDescriptionProps

export function AlertDescription({
  children,
  ...props
}: IAlertDescriptionProps) {
  return (
    <ChakraAlertDescription fontSize="1rem" lineHeight="normal" {...props}>
      {children}
    </ChakraAlertDescription>
  )
}
