export class CPF {
  static format(cpf: string): string {
    const regex = /(\d{3})(\d{3})(\d{3})(\d{2})/
    return cpf.replace(regex, '$1.$2.$3-$4')
  }

  static unFormat(cpf: string): string {
    return cpf.replace(/[.-]/g, '')
  }
}
