import { Text, TextProps } from '@chakra-ui/react'

type FormLegendProps = TextProps

export function FormLegend({ children, ...props }: FormLegendProps) {
  return (
    <Text
      as="legend"
      fontWeight="medium"
      fontSize={{ base: '1.125rem', md: '1.25rem' }}
      lineHeight="1.4375rem"
      textTransform="uppercase"
      minHeight="1.5rem"
      {...props}
    >
      {children}
    </Text>
  )
}
