import { useCallback, useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { GetRequestFilteredByIdOutput } from '.'
import { services } from '../../../../logic/core'
import { useAuth } from '../../../contexts/AuthContext'
import {
  ACTION_TYPES,
  getRequestFilteredByIdReducer,
  INITIAL_STATE_GET_REQUEST_FILTERED_BY_ID,
} from '../../../reducers'

export function useGetRequestFilteredById() {
  const { user } = useAuth()
  const profissionalId = user?.profissional_id
  const { orphanId, requestId, institutionId } = useParams()

  const [state, dispatch] = useReducer(
    getRequestFilteredByIdReducer,
    INITIAL_STATE_GET_REQUEST_FILTERED_BY_ID,
  )

  const execute = useCallback(
    async function () {
      const controller = new AbortController()
      const { signal } = controller
      dispatch({ type: ACTION_TYPES.FETCH_START })
      try {
        const config = {
          signal: signal,
        }
        const { results } =
          await services.requests.getFilteredById<GetRequestFilteredByIdOutput>(
            {
              profissionalId,
              institutionId,
              orphanId,
              requestId,
              config,
            },
          )
        const correctAttachments = results[0]?.anexos
          .map((attachment, index) => {
            if (attachment.valido == null) {
              return index + 1
            }
            return null
          })
          .filter((index: any) => index !== null) as number[]
        dispatch({
          type: ACTION_TYPES.FETCH_SUCCESS,
          steps: correctAttachments,
          request: results[0],
          allAttachments: results[0].anexos.length,
          correctAttachments: correctAttachments.length,
        })
      } catch (requestError: any) {
        const detail = requestError?.response?.data?.detail as string
        if (detail) {
          dispatch({
            type: ACTION_TYPES.FETCH_ERROR,
            error: `${detail}`,
          })
        } else {
          dispatch({
            type: ACTION_TYPES.FETCH_ERROR,
            error: 'Ocorreu um erro ao buscar os dados da solicitação.',
          })
        }
      }
    },
    [orphanId, requestId, profissionalId, institutionId],
  )

  useEffect(() => {
    if (!!profissionalId && !!orphanId && !!requestId) {
      execute()
    }
  }, [orphanId, requestId, profissionalId, execute])

  return {
    ...state,
    execute,
  }
}
