import { Alert } from '../Alert/Alert'

type TemplateAlertProps = {
  status: 'success' | 'error' | 'warning' | 'info'
  title: string
  description: string
}

export function TemplateAlert({
  status,
  title,
  description,
}: TemplateAlertProps) {
  return (
    <Alert.Root status={status}>
      <Alert.Icon />
      <Alert.Title>{title}</Alert.Title>
      <Alert.Description>{description}</Alert.Description>
    </Alert.Root>
  )
}
