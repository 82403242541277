import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IoIosSwap } from 'react-icons/io'
import { RiUpload2Line } from 'react-icons/ri'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  ButtonOutline,
  CardDocument,
  FormDocument as Form,
  InputFile,
} from '../../../components'
import { useAttachments } from '../../../data/contexts'
import { Attachment, useAttachFile } from '../../../data/hooks'
import {
  AttachmentFormData,
  AttachmentFormValidatorSchema,
} from '../../../logic/core'
import { api } from '../../../services/axios'

type InfoCard = {
  label: string
  required: boolean
  type: number
}

interface CardProps {
  infoCard: InfoCard
  attachment?: Attachment
}

export function Card({ infoCard, attachment = undefined }: CardProps) {
  const getAttachments = useAttachments()
  const attachFile = useAttachFile()
  const {
    trigger,
    setValue,
    formState: { errors },
  } = useForm<AttachmentFormData>({
    resolver: yupResolver(AttachmentFormValidatorSchema),
  })
  const { requestId } = useParams()
  const [paramRequestId, setParamRequestId] = useState<string>('')
  const [nameFile, setNameFile] = useState<string>('')
  const pathUrl = api.defaults.baseURL?.replace('/api/', '')
  const formRef = useRef<any>(null)

  async function submit(formData: FormData) {
    if (formData) {
      if (attachment) {
        await attachFile.handleFileAttachment(formData, attachment.id)
      } else {
        formData.append('tipo', String(infoCard.type))
        formData.append('solicitacao', paramRequestId)
        await attachFile.handleFileAttachment(formData)
      }
    }
  }

  async function handleFileChange(e: any) {
    const formData = new FormData()
    setNameFile(e.target.files[0].name)
    formData.append('nome_original', e.target.files[0].name)
    setValue('arquivo', e.target.files)

    const valid = await trigger('arquivo')
    if (!errors['arquivo'] && valid) {
      formData.append('arquivo', e.target.files[0])
      await submit(formData)
    }
  }

  function showErrors(error: any) {
    Object.entries(error).forEach(([_, value]) => {
      toast.error(`${value}`)
    })
  }

  useEffect(() => {
    if (attachFile.success) {
      toast.success(attachFile.success)
      getAttachments.execute()
    }
    if (attachFile.error) toast.error(attachFile.error)
    if (attachFile.errorData) showErrors(attachFile.errorData)
  }, [attachFile, getAttachments])

  useEffect(() => {
    if (requestId) {
      setParamRequestId(requestId)
    }
  }, [requestId])

  return (
    <CardDocument.Root>
      <CardDocument.Label>
        <CardDocument.Number>{infoCard.type}</CardDocument.Number>
        <CardDocument.Name>
          {infoCard.label}
          {infoCard.required && (
            <CardDocument.RequiredField>*</CardDocument.RequiredField>
          )}
        </CardDocument.Name>
      </CardDocument.Label>
      <CardDocument.Content>
        <Form.Root<Attachment>
          as="form"
          formRef={formRef}
          attachment={attachment}
        >
          {attachFile.loading ? (
            <Form.CancelSubmission
              nameFile={nameFile}
              label={infoCard.label}
              progress={attachFile.progress}
              handleCancelRequest={attachFile.handleFileAttachmentAbort}
            />
          ) : !attachment ? (
            <Form.Input width="100%" errors={errors.arquivo}>
              <InputFile
                display="none"
                error={errors.arquivo}
                onChange={handleFileChange}
              />

              <ButtonOutline.Blue
                as="div"
                icon={RiUpload2Line}
                minWidth="8.125rem"
              >
                Anexar
              </ButtonOutline.Blue>
            </Form.Input>
          ) : (
            <>
              {!errors.arquivo && (
                <Form.Link href={`${pathUrl}${attachment?.arquivo}`}>
                  {attachment.nome_original
                    ? attachment.nome_original
                    : infoCard.label}
                </Form.Link>
              )}
              <Form.Input errors={errors.arquivo}>
                <InputFile
                  display="none"
                  error={errors.arquivo}
                  onChange={handleFileChange}
                />

                <ButtonOutline.Blue
                  as="div"
                  icon={IoIosSwap}
                  minWidth="8.125rem"
                >
                  Substituir
                </ButtonOutline.Blue>
              </Form.Input>
            </>
          )}
        </Form.Root>
      </CardDocument.Content>
    </CardDocument.Root>
  )
}
