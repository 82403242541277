import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  BreadCrumb,
  FormControlItem,
  Input,
  Template,
  Button,
  Select,
} from '../../components'
import { benefits, districts } from '../../data/constants'
import { Coin } from '../../logic/utils'
import { api } from '../../services/axios'
import { queryClient } from '../../services/queryClient'
import {
  RegisterRequestFormValidatorSchema,
  formatRequestCreateData,
  RequestData,
  RegisterRequestFormData,
} from '../../logic/core'

export function CreateRequest() {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { orphanId, institutionId } = useParams()

  function handleConfirmCancel() {
    onClose()
    navigate(`/institutions/${institutionId}/orphans/${orphanId}/requests`)
  }

  const {
    register,
    handleSubmit,
    watch,
    resetField,
    control,
    formState: { errors, isSubmitting },
  } = useForm<RegisterRequestFormData>({
    resolver: yupResolver(RegisterRequestFormValidatorSchema),
  })

  const watchFieldFalecidoUmRecebiaBeneficio = watch(
    'falecido_um.recebia_beneficio',
  )
  const watchFieldFalecidoDoisRecebiaBeneficio = watch(
    'falecido_dois.recebia_beneficio',
  )
  const watchFieldTipoOrfandade = watch('tipo_orfandade')

  const disableComponents = watchFieldTipoOrfandade === null
  const showDeceasedFieldsTwo =
    Number(watchFieldTipoOrfandade) === 2 || disableComponents

  const legendArray = [
    {
      palavraChave: 'bilateral',
      text: 'Selecione bilateral quando ambos os pais, biológicos ou por adoção, faleceram, sendo, pelo menos um deles por COVID-19.',
    },
    {
      palavraChave: 'monoparental',
      text: 'Selecione monoparental quando a família é formada por apenas um dos pais e este faleceu por COVID-19.',
    },
  ]

  useEffect(() => {
    if (watchFieldFalecidoUmRecebiaBeneficio !== 'S') {
      resetField('falecido_um.fonte_beneficio', { defaultValue: '' })
      resetField('falecido_um.valor_beneficio', { defaultValue: '' })
    }
  }, [watchFieldFalecidoUmRecebiaBeneficio, resetField])

  useEffect(() => {
    if (watchFieldFalecidoDoisRecebiaBeneficio !== 'S') {
      resetField('falecido_dois.fonte_beneficio', { defaultValue: '' })
      resetField('falecido_dois.valor_beneficio', { defaultValue: '' })
    }
  }, [watchFieldFalecidoDoisRecebiaBeneficio, resetField])

  const createRequest = useMutation(
    async (requestData: RequestData) => {
      const { data } = await api.post('solicitacoes/', requestData)
      toast.warning(`${data.mensagem}`)
    },
    { onSuccess, onError },
  )

  function onSuccess() {
    queryClient.invalidateQueries('requests')
    navigate(`/institutions/${institutionId}/orphans/${orphanId}/requests`)
  }

  function onError(error: any) {
    const data = error?.response.data
    Object.entries(data).forEach(([key, value]) =>
      toast.error(`${key}: ${value}`),
    )
    if (data.length === 0) {
      toast.error(
        'Erro ao tentar cadastrar a solicitação, Verifique os dados informados e tente novamente!',
      )
    }
  }

  const handleRegisterRequest: SubmitHandler<RegisterRequestFormData> = async (
    data,
    event,
  ) => {
    event?.preventDefault()
    await new Promise((resolve) => setTimeout(resolve, 2000))
    const requestData = formatRequestCreateData(data, Number(orphanId))
    await createRequest.mutateAsync(requestData)
  }

  const resetFieldForms = useCallback(
    (value: number) => {
      if (value === 0) {
        resetField('falecido_um')
        resetField('falecido_dois')
      } else if (value === 1) {
        resetField('falecido_dois')
      }
    },
    [resetField],
  )

  useEffect(() => {
    const tipo = Number(watchFieldTipoOrfandade)
    resetFieldForms(tipo)
  }, [watchFieldTipoOrfandade, resetFieldForms])

  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  })

  document.title = 'RN-ACOLHE - Cadastrar solicitação'
  return (
    <Box
      as={'form'}
      flex={'1'}
      borderRadius={isWideVersion ? '8' : '0'}
      bg={'platinum.100'}
      p={['6', '8']}
      onSubmit={handleSubmit(handleRegisterRequest)}
    >
      <Template.CancelModal
        isOpen={isOpen}
        onClose={onClose}
        title="Cancelar cadastro de Solicitação!"
        children="Após a confirmação, os dados preenchidos serão perdidos. Deseja realmente confirmar o cancelamento?"
        confirmCancellation={handleConfirmCancel}
      />

      <BreadCrumb
        previousPaths={[
          { name: 'Órfãos', url: '/orphans' },
          {
            name: 'Solicitações',
            url: `/orphans/${orphanId}/requests`,
          },
        ]}
        currentPath="Cadastrar solicitação"
      />

      <Heading size={'lg'} fontWeight={'normal'} mb={'3rem'} mt={'0.5rem'}>
        Cadastrar solicitação
      </Heading>

      <Flex direction={'column'}>
        <FormControlItem
          _required
          label="Tipo de Orfandade"
          {...register('tipo_orfandade')}
          error={errors.tipo_orfandade}
          legend={legendArray}
        >
          <RadioGroup>
            <Flex h={'2.5rem'} gap={'1rem'}>
              <Radio value="1" {...register('tipo_orfandade')} minW="115px">
                Monoparental
              </Radio>
              <Radio value="2" {...register('tipo_orfandade')} minW="115px">
                Bilateral
              </Radio>
            </Flex>
          </RadioGroup>
        </FormControlItem>

        <Template.Fieldset
          marginTop="3rem"
          marginBottom="3rem"
          legend="Dados de pessoais"
          span="Dados pessoais do falecido 1"
        >
          <Grid
            templateAreas={{
              base: `"nome-falecido-um" "cpf-falecido-um"`,
              md: `"nome-falecido-um cpf-falecido-um"`,
              '2xl': `"nome-falecido-um nome-falecido-um cpf-falecido-um"`,
            }}
            gridTemplateColumns={{
              base: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
              '2xl': 'repeat(3, 1fr)',
            }}
            gap={'2rem'}
            marginBottom={'2rem'}
            w={'100%'}
          >
            <GridItem area={'nome-falecido-um'}>
              <Input
                label="Nome completo"
                type={'text'}
                {...register('falecido_um.nome')}
                error={errors.falecido_um?.nome}
                disabled={disableComponents}
              />
            </GridItem>

            <GridItem area={'cpf-falecido-um'}>
              <Input
                label="CPF"
                type={'text'}
                mask={'999.999.999-99'}
                placeholder="000.000.000-00"
                {...register('falecido_um.cpf')}
                error={errors.falecido_um?.cpf}
                disabled={disableComponents}
              />
            </GridItem>
          </Grid>
          <Grid
            templateAreas={{
              base: `"morte-covid-falecido-um" "uf-certidao-falecido-um" "data-certidao-falecido-um" "numero-certidao-falecido-um"`,
              md: `"morte-covid-falecido-um uf-certidao-falecido-um" "data-certidao-falecido-um numero-certidao-falecido-um"`,
              '2xl': `"morte-covid-falecido-um uf-certidao-falecido-um data-certidao-falecido-um" "numero-certidao-falecido-um numero-certidao-falecido-um numero-certidao-falecido-um"`,
            }}
            gridTemplateColumns={{
              base: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
              '2xl': 'repeat(3, 1fr)',
            }}
            gap={'2rem'}
            w={'100%'}
          >
            <GridItem area={'morte-covid-falecido-um'}>
              <FormControlItem
                _required
                label="Morte por COVID-19"
                {...register('falecido_um.morte_por_covid')}
                error={errors.falecido_um?.morte_por_covid}
                disabled={disableComponents}
              >
                <RadioGroup>
                  <Flex h={'2.5rem'} gap={'1rem'}>
                    <Radio
                      value={'S'}
                      {...register('falecido_um.morte_por_covid')}
                      minW="115px"
                    >
                      Sim
                    </Radio>
                    <Radio
                      value={'N'}
                      {...register('falecido_um.morte_por_covid')}
                      minW="115px"
                    >
                      Não
                    </Radio>
                  </Flex>
                </RadioGroup>
              </FormControlItem>
            </GridItem>

            <GridItem area={'uf-certidao-falecido-um'}>
              <Select
                label="UF da emissão da certidão de óbito"
                {...register('falecido_um.uf_emissao_certidao_obito')}
                error={errors.falecido_um?.uf_emissao_certidao_obito}
                disabled={disableComponents}
              >
                <option value="">--Selecione--</option>
                {districts.map((district) => (
                  <option key={district.id} value={district.acronym}>
                    {district.name}
                  </option>
                ))}
              </Select>
            </GridItem>

            <GridItem area={'data-certidao-falecido-um'}>
              <Input
                label="Data de emissão da certidão de óbito"
                type={'date'}
                {...register('falecido_um.data_emissao_certidao_obito')}
                error={errors.falecido_um?.data_emissao_certidao_obito}
                disabled={disableComponents}
              />
            </GridItem>

            <GridItem area={'numero-certidao-falecido-um'}>
              <Input
                label="Número certidão de óbito"
                type={'text'}
                mask={'999999 99 99 9999 9 99999 999 9999999-99'}
                placeholder="000000 00 00 0000 0 00000 000 0000000-00"
                {...register('falecido_um.numero_certidao_obito')}
                error={errors.falecido_um?.numero_certidao_obito}
                disabled={disableComponents}
              />
            </GridItem>
          </Grid>
        </Template.Fieldset>

        <Template.Fieldset
          marginBottom="3rem"
          legend="Fonte de renda"
          span="Fonte de renda do falecido 1"
        >
          <Grid
            templateAreas={{
              base: `"falecido-um-recebia-beneficio" "falecido-um-fonte-beneficio" "falecido-um-valor-beneficio"`,
              sm: `"falecido-um-recebia-beneficio falecido-um-recebia-beneficio" "falecido-um-fonte-beneficio falecido-um-valor-beneficio"`,
              md: `"falecido-um-recebia-beneficio falecido-um-fonte-beneficio falecido-um-valor-beneficio"`,
            }}
            gridTemplateColumns={{
              base: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
            }}
            gap={'2rem'}
            w={'100%'}
            mb={'3rem'}
          >
            <GridItem area={'falecido-um-recebia-beneficio'}>
              <FormControlItem
                _required
                label="Recebia benefício"
                {...register('falecido_um.recebia_beneficio')}
                error={errors.falecido_um?.recebia_beneficio}
                disabled={disableComponents}
              >
                <RadioGroup>
                  <Flex h={'2.5rem'} gap={'1rem'}>
                    <Radio
                      value={'S'}
                      {...register('falecido_um.recebia_beneficio')}
                      minW="115px"
                    >
                      Sim
                    </Radio>
                    <Radio
                      value={'N'}
                      {...register('falecido_um.recebia_beneficio')}
                      minW="115px"
                    >
                      Não
                    </Radio>
                  </Flex>
                </RadioGroup>
              </FormControlItem>
            </GridItem>

            <GridItem area={'falecido-um-fonte-beneficio'}>
              <Select
                label="Fonte do benefício"
                _required={watchFieldFalecidoUmRecebiaBeneficio === 'S'}
                {...register('falecido_um.fonte_beneficio')}
                error={errors.falecido_um?.fonte_beneficio}
                disabled={watchFieldFalecidoUmRecebiaBeneficio !== 'S'}
              >
                <option value="">--Selecione--</option>
                {benefits.map((benefit) => (
                  <option key={benefit.id} value={benefit.id}>
                    {benefit.name}
                  </option>
                ))}
              </Select>
            </GridItem>

            <GridItem area={'falecido-um-valor-beneficio'}>
              <Controller
                control={control}
                name="falecido_um.valor_beneficio"
                defaultValue=""
                render={({ field }) => (
                  <Input
                    as={NumberFormat}
                    label="Valor do benefício"
                    placeholder="R$ 000,00"
                    _required={watchFieldFalecidoUmRecebiaBeneficio === 'S'}
                    type={'text'}
                    disabled={watchFieldFalecidoUmRecebiaBeneficio !== 'S'}
                    error={errors.falecido_um?.valor_beneficio}
                    format={Coin.format}
                    isAllowed={Coin.validate}
                    {...field}
                  />
                )}
              />
            </GridItem>
          </Grid>
        </Template.Fieldset>

        {showDeceasedFieldsTwo && (
          <>
            <Template.Fieldset
              marginBottom="3rem"
              legend="Dados de pessoais"
              span="Dados pessoais do falecido 2"
            >
              <Grid
                templateAreas={{
                  base: `"nome-falecido-dois" "cpf-falecido-dois"`,
                  md: `"nome-falecido-dois cpf-falecido-dois"`,
                  '2xl': `"nome-falecido-dois nome-falecido-dois cpf-falecido-dois"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                  '2xl': 'repeat(3, 1fr)',
                }}
                gap={'2rem'}
                marginBottom={'2rem'}
                w={'100%'}
              >
                <GridItem area={'nome-falecido-dois'}>
                  <Input
                    label="Nome completo"
                    type={'text'}
                    {...register('falecido_dois.nome')}
                    error={errors.falecido_dois?.nome}
                    disabled={disableComponents}
                  />
                </GridItem>

                <GridItem area={'cpf-falecido-dois'} colSpan={1}>
                  <Input
                    label="CPF"
                    type={'text'}
                    mask={'999.999.999-99'}
                    placeholder="000.000.000-00"
                    {...register('falecido_dois.cpf')}
                    error={errors.falecido_dois?.cpf}
                    disabled={disableComponents}
                  />
                </GridItem>
              </Grid>

              <Grid
                templateAreas={{
                  base: `"morte-covid-falecido-dois" "uf-certidao-falecido-dois" "data-certidao-falecido-dois" "numero-certidao-falecido-dois"`,
                  md: `"morte-covid-falecido-dois uf-certidao-falecido-dois" "data-certidao-falecido-dois numero-certidao-falecido-dois"`,
                  '2xl': `"morte-covid-falecido-dois uf-certidao-falecido-dois data-certidao-falecido-dois" "numero-certidao-falecido-dois numero-certidao-falecido-dois numero-certidao-falecido-dois"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                  '2xl': 'repeat(3, 1fr)',
                }}
                gap={'2rem'}
                w={'100%'}
              >
                <GridItem area={'morte-covid-falecido-dois'}>
                  <FormControlItem
                    _required
                    label="Morte por COVID-19"
                    {...register('falecido_dois.morte_por_covid')}
                    error={errors.falecido_dois?.morte_por_covid}
                    disabled={disableComponents}
                  >
                    <RadioGroup>
                      <Flex h={'2.5rem'} gap={'1rem'}>
                        <Radio
                          value={'S'}
                          {...register('falecido_dois.morte_por_covid')}
                          minW="115px"
                        >
                          Sim
                        </Radio>
                        <Radio
                          value={'N'}
                          {...register('falecido_dois.morte_por_covid')}
                          minW="115px"
                        >
                          Não
                        </Radio>
                      </Flex>
                    </RadioGroup>
                  </FormControlItem>
                </GridItem>

                <GridItem area={'uf-certidao-falecido-dois'}>
                  <Select
                    label="UF da emissão da certidão de óbito"
                    {...register('falecido_dois.uf_emissao_certidao_obito')}
                    error={errors.falecido_dois?.uf_emissao_certidao_obito}
                    disabled={disableComponents}
                  >
                    <option value="">--Selecione--</option>
                    {districts.map((district) => (
                      <option key={district.id} value={district.acronym}>
                        {district.name}
                      </option>
                    ))}
                  </Select>
                </GridItem>

                <GridItem area={'data-certidao-falecido-dois'}>
                  <Input
                    label="Data de emissão da certidão de óbito"
                    type={'date'}
                    {...register('falecido_dois.data_emissao_certidao_obito')}
                    error={errors.falecido_dois?.data_emissao_certidao_obito}
                    disabled={disableComponents}
                  />
                </GridItem>

                <GridItem area={'numero-certidao-falecido-dois'}>
                  <Input
                    label="Número certidão de óbito"
                    type={'text'}
                    mask={'999999 99 99 9999 9 99999 999 9999999-99'}
                    placeholder="000000 00 00 0000 0 00000 000 0000000-00"
                    {...register('falecido_dois.numero_certidao_obito')}
                    error={errors.falecido_dois?.numero_certidao_obito}
                    disabled={disableComponents}
                  />
                </GridItem>
              </Grid>
            </Template.Fieldset>

            <Template.Fieldset
              marginBottom="3rem"
              legend="Fonte de renda"
              span="Fonte de renda do falecido 2"
            >
              <Grid
                templateAreas={{
                  base: `"falecido-dois-recebia-beneficio" "falecido-dois-fonte-beneficio" "falecido-dois-valor-beneficio"`,
                  sm: `"falecido-dois-recebia-beneficio falecido-dois-recebia-beneficio" "falecido-dois-fonte-beneficio falecido-dois-valor-beneficio"`,
                  md: `"falecido-dois-recebia-beneficio falecido-dois-fonte-beneficio falecido-dois-valor-beneficio"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                }}
                gap={'2rem'}
                w={'100%'}
              >
                <GridItem area={'falecido-dois-recebia-beneficio'}>
                  <FormControlItem
                    _required
                    label="Recebia benefício"
                    {...register('falecido_dois.recebia_beneficio')}
                    error={errors.falecido_dois?.recebia_beneficio}
                    disabled={disableComponents}
                  >
                    <RadioGroup>
                      <Flex h={'2.5rem'} gap={'1rem'}>
                        <Radio
                          value={'S'}
                          {...register('falecido_dois.recebia_beneficio')}
                          minW="115px"
                        >
                          Sim
                        </Radio>
                        <Radio
                          value={'N'}
                          {...register('falecido_dois.recebia_beneficio')}
                          minW="115px"
                        >
                          Não
                        </Radio>
                      </Flex>
                    </RadioGroup>
                  </FormControlItem>
                </GridItem>

                <GridItem area={'falecido-dois-fonte-beneficio'}>
                  <Select
                    label="Fonte do benefício"
                    _required={watchFieldFalecidoDoisRecebiaBeneficio === 'S'}
                    {...register('falecido_dois.fonte_beneficio')}
                    error={errors.falecido_dois?.fonte_beneficio}
                    disabled={watchFieldFalecidoDoisRecebiaBeneficio !== 'S'}
                  >
                    <option value="">--Selecione--</option>
                    {benefits.map((benefit) => (
                      <option key={benefit.id} value={benefit.id}>
                        {benefit.name}
                      </option>
                    ))}
                  </Select>
                </GridItem>

                <GridItem area={'falecido-dois-valor-beneficio'}>
                  <Controller
                    control={control}
                    name="falecido_dois.valor_beneficio"
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        as={NumberFormat}
                        label="Valor do benefício"
                        placeholder="R$ 000,00"
                        _required={
                          watchFieldFalecidoDoisRecebiaBeneficio === 'S'
                        }
                        type={'text'}
                        disabled={
                          watchFieldFalecidoDoisRecebiaBeneficio !== 'S'
                        }
                        error={errors.falecido_dois?.valor_beneficio}
                        format={Coin.format}
                        isAllowed={Coin.validate}
                        {...field}
                      />
                    )}
                  />
                </GridItem>
              </Grid>
            </Template.Fieldset>
          </>
        )}
      </Flex>
      <Flex mt={'3rem'} justify={'flex-end'}>
        <HStack spacing={'4'}>
          <Button.Tertiary onClick={onOpen}>Cancelar</Button.Tertiary>
          <Button.Primary
            type={'submit'}
            isLoading={isSubmitting}
            loadingText={'Salvando'}
            disabled={disableComponents}
          >
            Salvar
          </Button.Primary>
        </HStack>
      </Flex>
    </Box>
  )
}
