import { Flex, Heading, SimpleGrid, Spinner } from '@chakra-ui/react'
import { RiAddLine } from 'react-icons/ri'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AlertComponent,
  Content,
  Pagination,
  Subtitles,
  Button,
  BreadCrumb,
} from '../../components'
import { useAuth } from '../../data/contexts/AuthContext'
import { useGetRequest } from '../../data/hooks'
import { useRequestsPageStore } from '../../data/store'
import { TypeStatus } from '../../logic/core'
import { CardRequest } from './components/CardRequest'

const REGISTER_PER_PAGE = 9

export function RequestsList() {
  const { user } = useAuth()
  const { currentPage, setCurrentPage } = useRequestsPageStore()
  const { orphanId, institutionId } = useParams()
  const navigate = useNavigate()
  const { getRequest } = useGetRequest(
    REGISTER_PER_PAGE,
    currentPage,
    Number(orphanId),
    Number(institutionId),
    user?.profissional_id,
  )

  function disableRegisterRequestButton(): boolean {
    if (getRequest.data) {
      return !!getRequest.data.results.find(
        (request) => request.status !== TypeStatus.INDEFERIDA,
      )
    }
    return true
  }

  function showPagination(count: number) {
    return count > 9 && REGISTER_PER_PAGE === 9
  }

  return (
    <Content>
      <title>RN-ACOLHE - Solicitações</title>
      <BreadCrumb
        previousPaths={[
          { name: 'Instituições', url: '/institutions' },
          { name: 'Órfãos', url: `/institutions/${institutionId}/orphans/` },
        ]}
        currentPath="Solicitações"
      />
      <Flex
        mb={'8'}
        justify={'space-between'}
        align={'center'}
        wrap={'wrap'}
        gap={'4'}
      >
        <Heading size={'lg'} fontWeight={'normal'}>
          Solicitações
          {!getRequest.isLoading && getRequest.isFetching && (
            <Spinner size={'sm'} color={'blue.300'} ml={'4'} />
          )}
        </Heading>

        <Button.Secondary
          icon={RiAddLine}
          isDisabled={disableRegisterRequestButton()}
          onClick={() => navigate('create')}
          size="sm"
        >
          Cadastrar solicitação
        </Button.Secondary>
      </Flex>

      {getRequest.isLoading ? (
        <Flex color={'blue.300'} justify={'center'} align={'center'}>
          <Spinner />
        </Flex>
      ) : getRequest.error ? (
        <AlertComponent
          status="error"
          title="Ocorreu um erro"
          description={`Não foi possível listar as solicitações.`}
        />
      ) : !getRequest.data?.results.length ? (
        <AlertComponent
          status={'warning'}
          title={'Nenhuma solicitação encontrada.'}
          description={`Não encontramos nenhuma solicitação cadastrada. 
          Você pode cadastrar uma nova solicitação clicando no botão Cadastrar solicitação.`}
        />
      ) : (
        <>
          <Subtitles />
          <SimpleGrid
            flex="1"
            gap={'4'}
            minChildWidth={{ base: 'fit-content', sm: '20rem' }}
          >
            {getRequest.data?.results.map((request) => (
              <CardRequest key={request.id} request={request} />
            ))}
          </SimpleGrid>

          {getRequest.data?.results.length !== undefined &&
            getRequest.data?.results.length !== 0 && (
              <>
                {showPagination(getRequest.data.count) && (
                  <Pagination
                    totalCountOfRegisters={getRequest.data.count}
                    registersPerPage={REGISTER_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={setCurrentPage}
                    titlePage={'solicitações'}
                  />
                )}
              </>
            )}
        </>
      )}
    </Content>
  )
}
